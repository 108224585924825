import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";

import routes from "../../constants/routes";
import { PlanType } from "../../models";

import { useStore } from "../../stores";

export const ProFeatureLink = observer(() => {
  const { selectedRestaurant } = useStore("restaurants");

  return selectedRestaurant?.plan.type !== PlanType.Basic ? null : (
    <Link
      to={routes.restaurantSettings.replace(
        ":restaurantId",
        selectedRestaurant?.id || ""
      )}
    >
      <small className="ml-2 is-size-7">
        <img
          src="/images/crown.png"
          alt="crown"
          style={{ width: 24, height: 24, verticalAlign: "middle" }}
        />
      </small>
    </Link>
  );
});

import React from "react";
import { useField, FieldConfig, useFormikContext } from "formik";

export const Select = <T, V>(
  props: FieldConfig<V> & {
    disabled?: boolean;
    label?: string;
    options: T[];
    emptyValue?: V;
    getLabel?: (t: T) => string;
    getValue?: (t: T) => V;
  }
) => {
  const [field, { value, touched, error }, { setValue }] = useField(props);
  const { submitCount } = useFormikContext();

  const isTouched = touched || submitCount > 0;

  const getValue = (t: T) =>
    t && props.getValue ? props.getValue(t) : ((t as unknown) as V);

  return (
    <div className="field">
      <label className="label">{props.label}</label>
      <div className="select">
        <select
          {...field}
          onChange={(e) => {
            const val = Number(e.currentTarget.value);
            setValue(
              val === -1
                ? ((props.emptyValue as unknown) as V)
                : getValue(props.options[val])
            );
          }}
          value={props.options.findIndex((f) => getValue(f) === value)}
          disabled={props.disabled}
        >
          <option value={-1}>{props.label}</option>
          {props.options.map((m, i) => (
            <option key={i} value={i}>
              {props.getLabel ? props.getLabel(m) : m}
            </option>
          ))}
        </select>
      </div>
      {isTouched && !!error && <p className="help is-danger">{error}</p>}
    </div>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useField, FieldConfig } from "formik";
import { Link } from "react-router-dom";

import strings from "../../constants/strings";
import routes from "../../constants/routes";

import { Coupon, PlanType } from "../../models";

import { useStore } from "../../stores";

export const Coupons = (
  props: FieldConfig<Coupon[]> & {
    disabled?: boolean;
    label?: string;
  }
) => {
  const { t } = useTranslation();
  const [, { value, touched, error }, { setValue }] = useField(props);
  const { selectedRestaurant } = useStore("restaurants");

  const handleCodeChange = (index: number) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) =>
    setValue(
      value.map((m, i) =>
        i === index ? { ...m, code: event.currentTarget.value } : m
      )
    );
  const handleDiscountChange = (index: number) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) =>
    setValue(
      value.map((m, i) =>
        i === index ? { ...m, discount: Number(event.currentTarget.value) } : m
      )
    );
  const handleAdd = () => setValue([...value, { code: "", discount: 0 }]);
  const handleRemove = (index: number) => () =>
    setValue(value.filter((_, i) => i !== index));

  const isDisabled =
    props.disabled || selectedRestaurant?.plan.type === PlanType.Basic;

  return (
    <div className="field">
      <label className="label">
        {props.label}
        {selectedRestaurant?.plan.type === PlanType.Basic && (
          <Link
            to={routes.restaurantSettings.replace(
              ":restaurantId",
              selectedRestaurant?.id || ""
            )}
          >
            <small className="ml-2">
              (
              <img
                src="/images/crown.png"
                alt="crown"
                style={{ width: 24, height: 24, verticalAlign: "bottom" }}
              />{" "}
              {t(strings.proFeature)})
            </small>
          </Link>
        )}
      </label>
      <table className="table is-bordered is-fullwidth">
        <thead>
          <tr>
            <th>{t(strings.code)}</th>
            <th>{t(strings.discount)}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {value.map((c, i) => (
            <tr key={i}>
              <td>
                <input
                  className="input"
                  onChange={handleCodeChange(i)}
                  value={c.code || ""}
                />
                {touched && Array.isArray(error) && error[i]?.code && (
                  <>
                    <br />
                    <p className="help is-danger">{error[i]?.code}</p>
                  </>
                )}
              </td>
              <td>
                <input
                  className="input"
                  type="number"
                  onChange={handleDiscountChange(i)}
                  value={c.discount?.toString() || ""}
                />
                {touched && Array.isArray(error) && error[i]?.discount && (
                  <>
                    <br />
                    <p className="help is-danger">{error[i]?.discount}</p>
                  </>
                )}
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <button
                  className="delete"
                  type="button"
                  onClick={handleRemove(i)}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={3}>
              <button
                className="button is-light is-rounded"
                type="button"
                onClick={handleAdd}
                disabled={isDisabled}
              >
                {t(strings.add)}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      {touched && !!error && !Array.isArray(error) && (
        <p className="help is-danger">{error}</p>
      )}
    </div>
  );
};

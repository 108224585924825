import React from "react";

const App = () => {
  const AppRouter = window.location.pathname.toLowerCase().includes("web_view")
    ? require("./WebViewApp").default
    : require("./AppRouter").default;

  return (
    <React.StrictMode>
      <AppRouter />
    </React.StrictMode>
  );
};

export default App;

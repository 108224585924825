import { Schedule } from "./Schedule";
import { Cuisine } from "./Cuisine";
import { AvailableIntegrations } from "./Integration";

export enum PlanType {
  Basic = 0,
  Pro = 1,
  Plus = 2,
}

export enum PlanStatus {
  Active = 0,
  Cancelled = 1,
  FailedPayment = 2,
}

export type Coupon = {
  code: string;
  discount: number;
};

export type Plan = {
  type: PlanType;
  allowedBranches: number;
  stripeId?: string;
  stripeSubscriptionId?: string;
  status: PlanStatus;
  updatedAt: Date;
};

export type FacebookPage = {
  id: string;
  name: string;
  access_token: string;
};

export type CustomizationSettings = {
  primaryColor?: string;
  secondaryColor?: string;
  uiMode?: "compact" | "expanded";
};

export type Restaurant = {
  id: string;
  name: string;
  logo?: string;
  schedule?: Schedule;
  timezone: string;
  coupons?: Coupon[];
  preparationTime?: number;
  contactEmails?: string[];
  contactPhones?: string[];
  address?: string;
  country: string;
  countryCode?: string;
  currency: string;
  cuisine: Cuisine;
  botId?: number;
  botName?: string;
  botGreetingMessage?: string;
  plan: Plan;
  selectedPage?: FacebookPage;
  disableBranchProductExclusion?: boolean;
  disabled: boolean;
  integrations?: Record<AvailableIntegrations, Record<string, any>>;
  customization?: CustomizationSettings;
  createdAt: Date;
  updatedAt: Date;
};

export const planTypeMap = {
  [PlanType.Basic]: "basic",
  [PlanType.Plus]: "plus",
  [PlanType.Pro]: "pro",
};

import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import strings from "../../constants/strings";
import { logError } from "../../services/logging";

class _ErrorBoundary extends React.Component<
  WithTranslation,
  { hasError: boolean }
> {
  constructor(props: WithTranslation) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    logError(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <h1>{this.props.i18n?.t(strings.errorLoadingPage)}</h1>;
    }

    return this.props.children;
  }
}

const ErrorBoundary = withTranslation()(_ErrorBoundary);

export function ComponentLoader(loader: () => Promise<any>) {
  const Lazy = React.lazy(loader);

  return () => (
    <ErrorBoundary>
      <React.Suspense fallback={null}>
        <Lazy />
      </React.Suspense>
    </ErrorBoundary>
  );
}

import clsx from "clsx";
import React, { useCallback } from "react";

type Props = {
  min?: number;
  max: number;
  value: number;
  className?: string;
  vertical?: boolean;
  onChange: (value: number) => void;
};

export const Counter = ({
  min = 0,
  max,
  value,
  onChange,
  vertical,
  className,
}: Props) => {
  const handleAdd = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();

      if (value >= max) {
        return;
      }

      onChange(value + 1);
    },
    [max, onChange, value]
  );
  const handleSubtract = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();

      if (value <= min) {
        return;
      }

      onChange(value - 1);
    },
    [min, onChange, value]
  );

  const subtract = value > min && (
    <span
      className={clsx("icon is-clickable", {
        "has-text-primary": value,
        "mr-1": !vertical,
      })}
      onClick={handleSubtract}
    >
      <i className="fas fa-minus-circle"></i>
    </span>
  );

  const add = (
    <span
      className={clsx("icon is-clickable", {
        disabled: value >= max,
        "has-text-primary": value,
      })}
      onClick={handleAdd}
    >
      <i className="fas fa-plus-circle"></i>
    </span>
  );

  return (
    <div
      className={clsx("is-flex", className, {
        "is-flex-direction-column": vertical,
      })}
    >
      {vertical ? add : subtract}
      {value !== min && (
        <span
          className={clsx({ "mr-1": !vertical, "has-text-centered": vertical })}
        >
          {value}
        </span>
      )}
      {vertical ? subtract : add}
    </div>
  );
};

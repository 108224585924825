import React from "react";
import { useTranslation } from "react-i18next";
import { useField, FieldConfig } from "formik";
import TimeField from "react-simple-timefield";

import strings from "../../constants/strings";

import { Schedule as Model, Day, days } from "../../models";

export const Schedule = (
  props: FieldConfig<Model> & {
    disabled?: boolean;
    label?: string;
    tooltip?: string;
  }
) => {
  const { t } = useTranslation();
  const [, { value, touched, error }, { setValue }] = useField(props);

  const handleFromOnChange = (day: Day) => (
    from: React.ChangeEvent<HTMLInputElement>
  ) =>
    setValue({
      ...value,
      [day]: { ...value[day], from: from.target.value },
    });
  const handleToOnChange = (day: Day) => (
    to: React.ChangeEvent<HTMLInputElement>
  ) => setValue({ ...value, [day]: { ...value[day], to: to.target.value } });

  return (
    <div className="field">
      <label className="label">
        {props.label}
        {!!props.tooltip && <p className="label is-small">({props.tooltip})</p>}
      </label>
      <table className="table is-bordered is-fullwidth">
        <thead>
          <tr>
            <th>{t(strings.day)}</th>
            <th>{t(strings.from)}</th>
            <th>{t(strings.to)}</th>
          </tr>
        </thead>
        <tbody>
          {days.map((d) => (
            <tr key={d}>
              <th>{t(d)}</th>
              <td>
                <TimeField
                  onChange={handleFromOnChange(d)}
                  value={value[d]?.from || "00:00"}
                  showSeconds={false}
                  input={<input className="input" />}
                />
              </td>
              <td>
                <TimeField
                  onChange={handleToOnChange(d)}
                  value={value[d]?.to || "00:00"}
                  showSeconds={false}
                  input={<input className="input" />}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {touched && !!error && <p className="help is-danger">{error}</p>}
    </div>
  );
};

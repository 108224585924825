import { useEffect, useRef } from "react";
import { useField, FieldConfig } from "formik";
import clsx from "clsx";
// @ts-ignore
import BulmaTagsInput from "@creativebulma/bulma-tagsinput";

type Props<T> = {
  source: { text: string; value: T }[];
  label?: string;
  id?: string;
  disabled?: boolean;
} & FieldConfig<T[]>;

export const TagsInput = <T,>({ id, label = "", ...props }: Props<T>) => {
  const [field, { value, touched, error }, { setValue }] = useField<T[]>(props);
  const sourceRef = useRef<{ text: string; value: T }[]>(props.source);
  sourceRef.current = props.source;

  const tagsRef = useRef(null);
  const tagsInputRef = useRef<BulmaTagsInput>();

  useEffect(() => {
    tagsInputRef.current = new BulmaTagsInput(tagsRef.current, {
      source: (v: string) =>
        sourceRef.current.filter(
          (s) => !v || s.text.toLowerCase().includes(v.toLowerCase())
        ),
    });
  }, []);

  useEffect(() => {
    tagsInputRef.current.value = value.map((m) =>
      props.source.find((f) => f.value === m)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tagsInputRef.current) {
      return;
    }
    tagsInputRef.current.on("after.add", () => {
      setValue(tagsInputRef.current.value);
    });
    tagsInputRef.current.on("after.remove", () =>
      setValue(tagsInputRef.current.value)
    );
    tagsInputRef.current.on("after.flush", () =>
      setValue(tagsInputRef.current.value)
    );
    tagsInputRef.current.on("after.select", () => {
      setValue(tagsInputRef.current.value);
    });
  }, [setValue]);

  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <select
          key={field.name}
          name={field.name}
          className={clsx("input", {
            "is-danger": touched && error,
          })}
          ref={tagsRef}
          id={id}
          multiple
          onBlur={field.onBlur}
          onChange={() => {}}
        ></select>
      </div>
      {touched && !!error && <p className="help is-danger">{error}</p>}
    </div>
  );
};

import React from "react";
// import { TFunctionResult } from "i18next";
import clsx from "clsx";
import { useField, FieldConfig } from "formik";

type Props = {
  label: string;
  tooltip: string;
} & FieldConfig<string>;

export function Switch(props: Props) {
  const [field, meta] = useField(props);
  const { value } = meta;

  return (
    <div className="field">
      <div
        className="has-tooltip-arrow text-center is-inline-block"
        data-tooltip={props.tooltip}
      >
        <input
          id={`sw-${props.label}`}
          type="checkbox"
          className={clsx("switch is-info", {
            "is-danger": meta.touched && meta.error,
          })}
          {...field}
          {...props}
          checked={value ? true : false}
        />
        <label htmlFor={`sw-${props.label}`}>{props.label}</label>
        <div>
          {meta.touched && !!meta.error && (
            <p className="help is-danger">{meta.error}</p>
          )}
        </div>
      </div>
    </div>
  );
}

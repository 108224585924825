const routes = {
  home: "/",
  notFound: "/404",
  signUp: "/sign-up",
  signIn: "/sign-in",
  terms: "/terms",
  createRestaurant: "/shops/create",
  restaurants: "/shops",
  createRestaurantBranch: "/shops/:restaurantId/branches/add",
  restaurantBranches: "/shops/:restaurantId/branches",
  restaurantMenu: "/shops/:restaurantId/menu",
  restaurantProfile: "/shops/:restaurantId/profile",
  restaurantSettings: "/shops/:restaurantId/settings",
  restaurantIntegrations: "/shops/:restaurantId/integrations",
  branchMenu: "/shops/:restaurantId/branches/:branchId/menu",
  branchOrders: "/shops/:restaurantId/branches/:branchId/orders",
  branchProfile: "/shops/:restaurantId/branches/:branchId/profile",
  webViewMenuItemModifier: "/web_view/add_menu_item",
  webViewConfirm: "/web_view/confirm",
  webViewDelivery: "/web_view/delivery",
  webViewBranch: "/web_view/branch",
  webViewCheckOutEdnaPay: "/web_view/checkoutEdnaPay",
  webViewOrder: "/web_view/order/:restaurantId",
  webViewOrderMenu: "/web_view/order/:restaurantId/:branchId/menu",
  webViewOrderCheckout: "/web_view/order/:restaurantId/:branchId/checkout",
  webViewOrderComplete: "/web_view/order/:restaurantId/:branchId/complete",
  webViewOrderTracking: "/web_view/order/:restaurantId/track",
};

export const publicRoutes = [
  routes.notFound,
  routes.signIn,
  routes.signUp,
  routes.terms,
  routes.webViewMenuItemModifier,
  routes.webViewConfirm,
];

export default routes;

import React, { useEffect, useRef } from "react";
// @ts-ignore
import bulmaCollapsible from "@creativebulma/bulma-collapsible";

export const Collapsible = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const collapsiblesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (collapsiblesRef.current) {
      bulmaCollapsible.attach(".is-collapsible", {
        container: collapsiblesRef.current,
      });
    }
  }, []);

  return (
    <div ref={collapsiblesRef} className={className}>
      {children}
    </div>
  );
};

import { DetailedHTMLProps, ImgHTMLAttributes, useEffect, useRef } from "react";

export const LocalImage = ({
  file,
  ...rest
}: { file: Blob; alt: string } & DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>) => {
  const ref = useRef<HTMLImageElement | null>(null);
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const img = ref.current;

    img.src = URL.createObjectURL(file);
    img.onload = function () {
      URL.revokeObjectURL(img.src); // free memory
    };
  }, [file]);

  return <img ref={ref} {...rest} alt={rest.alt} />;
};

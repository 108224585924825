import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { useStripe } from "@stripe/react-stripe-js";

import { Modal, Field, CardField } from "../components";

import strings from "../constants/strings";
import { PlanType, planTypeMap, UserType, PlanStatus, Plan } from "../models";
import { useStore } from "../stores";
import { logError } from "../services/logging";

const UpdatePaymentModal = observer(() => {
  const { t } = useTranslation();
  const { selectedRestaurant, updatePaymentMethod } = useStore("restaurants");
  const { user } = useStore("auth");
  const stripe = useStripe();

  const schema = yup.object().shape({
    type: yup
      .number()
      .equals([PlanType.Pro], t(strings.requiredField))
      .required(t(strings.requiredField)),
    allowedBranches: yup
      .number()
      .min(1, t(strings.needAtLeastOneBranch))
      .required(t(strings.requiredField)),
    creditCard: yup.object().required(t(strings.requiredField)),
  });

  const open =
    selectedRestaurant?.plan.status === PlanStatus.FailedPayment &&
    user?.type !== UserType.Administrator;

  return open ? (
    <Formik
      initialValues={
        {
          creditCard: undefined,
          ...selectedRestaurant?.plan,
        } as Plan & { creditCard: any }
      }
      validationSchema={schema}
      onSubmit={async (data, helpers) => {
        if (!stripe) {
          return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: data.creditCard as any,
        });

        if (error || !paymentMethod) {
          logError(error || "no payment method generated");
          helpers.setFieldError(
            "creditCard",
            error?.message || t(strings.genericError)
          );
          return;
        }

        const success = await updatePaymentMethod(paymentMethod.id);

        if (success) {
          toast.success(t(strings.updateSucceeded));
        } else {
          toast.error(t(strings.genericError));
        }
      }}
    >
      {({ isSubmitting, values, submitForm }) => (
        <Modal
          isOpen
          title={t(strings.updatePaymentMethod)}
          primaryText={t(strings.confirm)}
          primaryLoading={isSubmitting}
          onPrimary={submitForm}
        >
          <Form autoComplete="off">
            <strong>{t(strings.plan)}:</strong> {t(planTypeMap[values.type])}
            <Field
              type="number"
              name="allowedBranches"
              label={t(strings.allowedBranches)}
              disabled={isSubmitting}
              readOnly
            />
            <strong>{t(strings.total)}:</strong> $
            {(Number(values.allowedBranches) || 0) > 0
              ? (Number(values.allowedBranches) || 0) * 30
              : 0}
            /{t(strings.month)}
            <br />
            <br />
            <CardField name="creditCard" />
          </Form>
        </Modal>
      )}
    </Formik>
  ) : null;
});

export default UpdatePaymentModal;

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Geosuggest, { Suggest } from "react-geosuggest";
import Papa from "papaparse";

import strings from "../../constants/strings";

import { Location } from "../../models";
import { Modal } from "../../components";
import { logError } from "../../services/logging";

import MapPicker from "./MapPicker";
import clsx from "clsx";
import useGoogleMapsLib from "../../hooks/useGoogleMapsLib";

export const Search = ({
  onSelect,
  countryCode,
  isFullScreen,
}: {
  onSelect: (
    value: Location & {
      address: string;
      city: string;
      state: string;
      postalCode: string;
    }
  ) => void;
  countryCode?: string;
  isFullScreen?: boolean;
}) => {
  const { t } = useTranslation();
  const [showMap, setShowMap] = useState(false);
  const [coords, setCoords] = useState<Location>();
  const [tempCoords, setTempCoords] = useState<Location>();
  const [loading, setLoading] = useState(false);
  const { ready } = useGoogleMapsLib();

  useEffect(() => {
    Papa.parse("/misc/country_coords.csv", {
      download: true,
      complete: (result) => {
        const c: any = result.data.find(([code]: any) => code === countryCode);
        if (c) {
          const co = { latitude: Number(c[1]), longitude: Number(c[2]) };
          setCoords(co);
          setTempCoords(co);
        }
      },
    });
  }, [countryCode]);

  const handleOnChange = useCallback((value: Suggest) => {
    if (!value?.gmaps) {
      return;
    }

    try {
      const json = value.gmaps.geometry.location.toJSON();
      setTempCoords({
        latitude: json.lat,
        longitude: json.lng,
      });
      setShowMap(true);
    } catch (error) {
      logError(error);
    }
  }, []);

  const selectCurrentLocation = useCallback(() => {
    setLoading(true);
    navigator.geolocation?.getCurrentPosition(
      (position) => {
        setTempCoords(position.coords);
        setLoading(false);
        setShowMap(true);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  return ready ? (
    <>
      <Geosuggest
        country={countryCode}
        queryDelay={500}
        minLength={3}
        className="control"
        inputClassName="input"
        placeholder={t(strings.typeAddress)}
        onSuggestSelect={handleOnChange}
        suggestItemClassName="is-clickable card is-shadowless p-3 mt-2"
      />
      <div className="divider my-0 pt-3">{t(strings.or)}</div>
      <div className="is-flex is-justify-content-center">
        {!!navigator.geolocation && (
          <button
            className={clsx("button is-ghost", {
              "is-loading": loading,
            })}
            onClick={selectCurrentLocation}
          >
            <span className="icon">
              <i className="fas fa-location-arrow"></i>
            </span>
            <span>{t(strings.useCurrentLocation)}</span>
          </button>
        )}
      </div>
      {showMap && !!coords && (
        <Modal
          isOpen
          isFullScreen={isFullScreen}
          isNonScrollable
          title={t(strings.selectPlaceOnMap)}
          primaryText={t(strings.confirm)}
          onPrimary={() => {
            setShowMap(false);
            if (!tempCoords) {
              return;
            }
            new google.maps.Geocoder().geocode(
              {
                location: new google.maps.LatLng(
                  tempCoords.latitude,
                  tempCoords.longitude
                ),
              },
              ([res], r) => {
                if (!tempCoords || r !== google.maps.GeocoderStatus.OK) {
                  return;
                }

                onSelect({
                  latitude: tempCoords.latitude,
                  longitude: tempCoords.longitude,
                  address: res.formatted_address,
                  city:
                    res.address_components.find((f) =>
                      f.types.includes("administrative_area_level_2")
                    )?.long_name ||
                    res.address_components.find((f) =>
                      f.types.includes("administrative_area_level_3")
                    )?.long_name ||
                    t(strings.unknown),
                  state:
                    res.address_components.find((f) =>
                      f.types.includes("administrative_area_level_1")
                    )?.long_name || t(strings.unknown),
                  postalCode:
                    res.address_components.find((f) =>
                      f.types.includes("postal_code")
                    )?.long_name || t(strings.unknown),
                });
              }
            );
          }}
          onClose={() => setShowMap(false)}
        >
          <MapPicker
            defaultLocation={tempCoords || coords}
            zoom={tempCoords !== coords ? 17 : 7}
            style={{
              width: "unset",
              height: "100%",
              margin: "-20px",
              marginBottom: "20px",
              flex: 1,
              minHeight: !isFullScreen ? 300 : "unset",
            }}
            options={{ disableDefaultUI: true, zoomControl: true }}
            onChangeLocation={(latitude, longitude) =>
              setTempCoords({ latitude, longitude })
            }
          />
          <article
            className="message is-info"
            style={{
              margin: "-20px",
            }}
          >
            <div
              className="message-body"
              style={{
                borderRadius: 0,
              }}
            >
              {t(strings.markBuildingEntranceForDelivery)}
            </div>
          </article>
        </Modal>
      )}
    </>
  ) : null;
};

const strings = {
  notFound: "notFound",
  email: "email",
  password: "password",
  signIn: "signIn",
  signUp: "signUp",
  signUpWelcome: "signUpWelcome",
  signOut: "signOut",
  restaurants: "restaurants",
  invalidEmail: "invalidEmail",
  fieldRequired: "fieldRequired",
  passwordMinLength: "passwordMinLength",
  userLoggedIn: "userLoggedIn",
  userDisabled: "userDisabled",
  userFound: "userFound",
  userNotFound: "userNotFound",
  wrongPassword: "wrongPassword",
  genericError: "genericError",
  emailAlreadyInUse: "emailAlreadyInUse",
  weakPassword: "weakPassword",
  userCreated: "userCreated",
  recoveryEmailSent: "recoveryEmailSent",
  verificationEmailSent: "verificationEmailSent",
  emailVerified: "emailVerified",
  emailNotVerified: "emailNotVerified",
  requiredField: "requiredField",
  invalidField: "invalidField",
  requiresRecentLogin: "requiresRecentLogin",
  updateSucceeded: "updateSucceeded",
  acceptTerms: "acceptTerms",
  search: "search",
  add: "add",
  name: "name",
  logo: "logo",
  orders: "orders",
  menu: "menu",
  branchMenu: "branchMenu",
  settings: "settings",
  restaurantProfile: "restaurantProfile",
  branchProfile: "branchProfile",
  console: "console",
  day: "day",
  from: "from",
  to: "to",
  sunday: "sunday",
  monday: "monday",
  tuesday: "tuesday",
  wednesday: "wednesday",
  thursday: "thursday",
  friday: "friday",
  saturday: "saturday",
  save: "save",
  schedule: "schedule",
  file: "file",
  coupons: "coupons",
  code: "code",
  discount: "discount",
  preparationTime: "preparationTime",
  contactEmails: "contactEmails",
  contactPhones: "contactPhones",
  notificationPhones: "notificationPhones",
  address: "address",
  botId: "botId",
  botName: "botName",
  botGreetingMessage: "botGreetingMessage",
  branches: "branches",
  cash: "cash",
  creditCard: "creditCard",
  express: "express",
  inRestaurant: "inRestaurant",
  pickUp: "pickUp",
  time: "time",
  price: "price",
  zone: "zone",
  paymentMethods: "paymentMethods",
  deliveryTypes: "deliveryTypes",
  deliveryCoverage: "deliveryCoverage",
  deliveryCoverageType: "deliveryCoverageType",
  delete: "delete",
  currency: "currency",
  image: "image",
  minimumSelectedItems: "minimumSelectedItems",
  maximumSelectedItems: "maximumSelectedItems",
  perProduct: "perProduct",
  minimumValidation: "minimumValidation",
  maximumValidation: "maximumValidation",
  isModifierRequired: "isModifierRequired",
  modifierItems: "modifierItems",
  description: "description",
  menuModifiers: "menuModifiers",
  menuCategories: "menuCategories",
  menuInternalCode: "menuInternalCode",
  menuItems: "menuItems",
  addMenuCategory: "addMenuCategory",
  onlyPositiveValues: "onlyPositiveValues",
  addMenuItem: "addMenuItem",
  addMenuModifier: "addMenuModifier",
  isRequired: "isRequired",
  isOptional: "isOptional",
  isExcluded: "isExcluded",
  min: "min",
  max: "max",
  exclude: "exclude",
  excludeItems: "excludeItems",
  include: "include",
  includeAll: "includeAll",
  excluded: "excluded",
  included: "included",
  otherPaymentMethod: "otherPaymentMethod",
  otherPaymentMethodName: "otherPaymentMethodName",
  otherPaymentMethodImage: "otherPaymentMethodImage",
  otherPaymentMethodDescription: "otherPaymentMethodDescription",
  total: "total",
  items: "items",
  ordersRequests: "ordersRequests",
  readyForDelivery: "readyForDelivery",
  beingPrepared: "beingPrepared",
  paymentMethod: "paymentMethod",
  minutes: "minutes",
  deliveryZone: "deliveryZone",
  deliveryAddress: "deliveryAddress",
  referencePhone: "referencePhone",
  deliveryName: "deliveryName",
  deliveryNameTable: "deliveryNameTable",
  deliveryType: "deliveryType",
  order: "order",
  prepare: "prepare",
  prepareAll: "prepareAll",
  toolTipPrepareAll: "toolTipPrepareAll",
  modalTitlePrepareAll: "modalTitlePrepareAll",
  sendToDeliveryAll: "sendToDeliveryAll",
  toolTipSendToDeliveryAll: "toolTipSendToDeliveryAll",
  modalTitleSendToDeliveryAll: "modalTitleSendToDeliveryAll",
  deliveryCompleteAll: "deliveryCompleteAll",
  toolTipDeliveryCompleteAll: "toolTipDeliveryCompleteAll",
  disableNotifications: "disableNotifications",
  modalTitleDeliveryCompleteAll: "modalTitleDeliveryCompleteAll",
  disableNotificationsTT: "disableNotificationsTT",
  disableOrderTrackingTT: "disableOrderTrackingTT",
  disableOrderTracking: "disableOrderTracking",
  disableEstimateDeliveryTime: "disableEstimateDeliveryTime",
  disableEstimateDeliveryTimeTT: "disableEstimateDeliveryTimeTT",
  delivered: "delivered",
  note: "note",
  channel: "channel",
  channelInfo: "channelInfo",
  createOrder: "createOrder",
  activeOrders: "activeOrders",
  finishedOrders: "finishedOrders",
  confirm: "confirm",
  webAdmin: "webAdmin",
  admin: "admin",
  messenger: "messenger",
  whatsApp: "whatsApp",
  cancellationReason: "cancellationReason",
  cancel: "cancel",
  cancelOrder: "cancelOrder",
  currentPlan: "currentPlan",
  choose: "choose",
  basic: "basic",
  free: "free",
  pro: "pro",
  promoCodes: "promoCodes",
  plus: "plus",
  branch: "branch",
  errorLoadingPage: "errorLoadingPage",
  loading: "loading",
  couponCode: "couponCode",
  appliedCoupon: "appliedCoupon",
  subtotal: "subtotal",
  country: "country",
  invalidPhoneNumber: "invalidPhoneNumber",
  categoryAlreadyExists: "categoryAlreadyExists",
  edit: "edit",
  askDeleteConfirmation: "askDeleteConfirmation",
  deactivate: "deactivate",
  askDeactivateConfirmation: "askDeactivateConfirmation",
  activate: "activate",
  askActivateConfirmation: "askActivateConfirmation",
  nothingHereYet: "nothingHereYet",
  deliveryTime: "deliveryTime",
  changesReceivedWillBeAppliedSoon: "changesReceivedWillBeAppliedSoon",
  askExcludeConfirmation: "askExcludeConfirmation",
  askIncludeConfirmation: "askIncludeConfirmation",
  arrivalTime: "arrivalTime",
  arrivalTimeReady: "arrivalTimeReady",
  arrivalMinutes: "arrivalMinutes",
  addMoreToReachMinimum: "addMoreToReachMinimum",
  orderId: "orderId",
  noDuplicates: "noDuplicates",
  orderIsAlreadySent: "orderIsAlreadySent",
  youCancelledThisOrder: "youCancelledThisOrder",
  restaurantName: "restaurantName",
  branchName: "branchName",
  textIsTooLong: "textIsTooLong",
  city: "city",
  state: "state",
  postalCode: "postalCode",
  mustBeEqualOrGreaterThanMinimum: "mustBeEqualOrGreaterThanMinimum",
  managers: "managers",
  invalidCoupon: "invalidCoupon",
  upgradePlan: "upgradePlan",
  currentPlanUpgrade: "currentPlanUpgrade",
  basicPlanDescription: "basicPlanDescription",
  proPlanDescription: "proPlanDescription",
  plusPlanDescription: "plusPlanDescription",
  contactUs: "contactUs",
  willSendToUser: "willSendToUser",
  needAtLeastOneBranch: "needAtLeastOneBranch",
  plan: "plan",
  allowedBranches: "allowedBranches",
  youHaveBeenUpgraded: "youHaveBeenUpgraded",
  includesPreparationTime: "includesPreparationTime",
  facebookUrl: "facebookUrl",
  phoneNumber: "phoneNumber",
  month: "month",
  updatePaymentMethod: "updatePaymentMethod",
  proFeature: "proFeature",
  agreePlanUpdate: "agreePlanUpdate",
  closed: "closed",
  cuisine: "cuisine",
  cuisines: {
    africanEthiopian: "cuisines.africanEthiopian",
    africanOther: "cuisines.africanOther",
    alcohol: "cuisines.alcohol",
    american: "cuisines.american",
    argentinian: "cuisines.argentinian",
    asianFusion: "cuisines.asianFusion",
    asianOther: "cuisines.asianOther",
    bbq: "cuisines.bbq",
    bakery: "cuisines.bakery",
    barOrPubFood: "cuisines.barOrPubFood",
    brazilian: "cuisines.brazilian",
    breakfastAndBrunch: "cuisines.breakfastAndBrunch",
    bubbleTea: "cuisines.bubbleTea",
    burgers: "cuisines.burgers",
    burmese: "cuisines.burmese",
    cajunOrCreole: "cuisines.cajunOrCreole",
    caribbean: "cuisines.caribbean",
    chicken: "cuisines.chicken",
    chilean: "cuisines.chilean",
    chineseCantonese: "cuisines.chineseCantonese",
    chineseHotPot: "cuisines.chineseHotPot",
    chineseNoodlesAndDumplings: "cuisines.chineseNoodlesAndDumplings",
    chineseOther: "cuisines.chineseOther",
    chineseSichuan: "cuisines.chineseSichuan",
    chineseTaiwanese: "cuisines.chineseTaiwanese",
    coffeeAndTea: "cuisines.coffeeAndTea",
    colombian: "cuisines.colombian",
    comfortFood: "cuisines.comfortFood",
    dessertOther: "cuisines.dessertOther",
    europeanOther: "cuisines.europeanOther",
    fishAndChips: "cuisines.fishAndChips",
    french: "cuisines.french",
    georgian: "cuisines.georgian",
    german: "cuisines.german",
    gourmet: "cuisines.gourmet",
    halal: "cuisines.halal",
    healthy: "cuisines.healthy",
    iceCreamAndFrozenYogurt: "cuisines.iceCreamAndFrozenYogurt",
    indian: "cuisines.indian",
    indonesian: "cuisines.indonesian",
    italian: "cuisines.italian",
    japaneseOther: "cuisines.japaneseOther",
    japaneseRamen: "cuisines.japaneseRamen",
    japaneseSushi: "cuisines.japaneseSushi",
    juiceAndSmoothies: "cuisines.juiceAndSmoothies",
    korean: "cuisines.korean",
    kosher: "cuisines.kosher",
    latinAmericanOther: "cuisines.latinAmericanOther",
    malaysian: "cuisines.malaysian",
    mediterranean: "cuisines.mediterranean",
    mexican: "cuisines.mexican",
    middleEastern: "cuisines.middleEastern",
    modernAustralian: "cuisines.modernAustralian",
    other: "cuisines.other",
    peruvian: "cuisines.peruvian",
    pizza: "cuisines.pizza",
    russian: "cuisines.russian",
    saladOrSandwiches: "cuisines.saladOrSandwiches",
    seafood: "cuisines.seafood",
    snacks: "cuisines.snacks",
    soulFood: "cuisines.soulFood",
    southern: "cuisines.southern",
    spanish: "cuisines.spanish",
    steakhouse: "cuisines.steakhouse",
    thai: "cuisines.thai",
    vegetarianOrVegan: "cuisines.vegetarianOrVegan",
    vietnamese: "cuisines.vietnamese",
    wings: "cuisines.wings",
  },
  continueWithFacebook: "continueWithFacebook",
  facebookPage: "facebookPage",
  timezone: "timezone",
  needsAccountLinking: "needsAccountLinking",
  linkAccounts: "linkAccounts",
  needsAccountCreation: "needsAccountCreation",
  facebookPermissionsDisclaimer: "facebookPermissionsDisclaimer",
  signInWithFacebookTitle: "signInWithFacebookTitle",
  or: "or",
  botNameToolTip: "botNameToolTip",
  greetingToolTip: "greetingToolTip",
  listToolTip: "listToolTip",
  preparationTimeToolTip: "preparationTimeToolTip",
  deliveryCoverageToolTip: "deliveryCoverageToolTip",
  inRestaurantTypes: "inRestaurantTypes",
  table: "table",
  stopSound: "stopSound",
  typeAddress: "typeAddress",
  selectPlaceOnMap: "selectPlaceOnMap",
  markBuildingEntranceForDelivery: "markBuildingEntranceForDelivery",
  deliveryNotes: "deliveryNotes",
  highestPrice: "highestPrice",
  minimumProductQty: "minimumProductQty",
  promotionType: "promotionType",
  isPromotional: "isPromotional",
  promotionParameter: "promotionParameter",
  makeSureFirstValueIsHigher: "makeSureFirstValueIsHigher",
  outOfDeliveryCoverage: "outOfDeliveryCoverage",
  unknown: "unknown",
  distance: "distance",
  export: "export",
  creating: "creating",
  received: "received",
  preparing: "preparing",
  awaitingDelivery: "awaitingDelivery",
  canceled: "canceled",
  canceledByUser: "canceledByUser",
  freeDelivery: "freeDelivery",
  freeDeliveryOnMinItemsQuantity: "freeDeliveryOnMinItemsQuantity",
  minimumProduct: "minimumProduct",
  minimumProductError: "minimumProductError",
  branchClickHere: "branchClickHere",
  howToDocument: "howToDocument",
  ednaPayLink: "ednaPayLink",
  ednaPayLinkLong: "ednaPayLinkLong",
  verifiedAccount: "verifiedAccount",
  verifiedAccountToolTip: "verifiedAccountToolTip",
  verifiedBusiness: "verifiedBusiness",
  verifiedBusinessToolTip: "verifiedBusinessToolTip",
  verifiedEmail: "verifiedEmail",
  verifiedEmailToolTip: "verifiedEmailToolTip",
  verifiedPhone: "verifiedPhone",
  verifiedPhoneToolTip: "verifiedPhoneToolTip",
  verifiedKYC: "verifiedKYC",
  verifiedKYCToolTip: "verifiedKYCToolTip",
  createAccount: "createAccount",
  disabledEdnaPay: "disabledEdnaPay",
  ednaPay: "ednaPay",
  refresh: "refresh",
  ednaPayLinked: "ednaPayLinked",
  validatingPayment: "validatingPayment",
  paymentCompleted: "paymentCompleted",
  verifiedTermsAndConditions: "verifiedTermsAndConditions",
  refreshing: "refreshing",
  txAmount: "txAmount",
  txId: "txId",
  txfee: "txfee",
  conectEdnaPayAccount: "conectEdnaPayAccount",
  howToFix: "howToFix",
  payVerificationText: "payVerificationText",
  orderingSelectBranch: "orderingSelectBranch",
  selectBranch: "selectBranch",
  orderingBranchSelected: "orderingBranchSelected",
  you: "you",
  letsGo: "letsGo",
  generatePay: "generatePay",
  finishOrder: "finishOrder",
  subtotalSummary: "subtotalSummary",
  cancelledSummary: "cancelledSummary",
  expressPriceText: "expressPriceText",
  discountedSubtotalText: "discountedSubtotalText",
  paymentMethodInfo: "paymentMethodInfo",
  productAddedToCart: "productAddedToCart",
  orderClosing: "orderClosing",
  orderClosingAfterCancelled: "orderClosingAfterCancelled",
  orderButton: "orderButton",
  orderButtonNoTracking: "orderButtonNoTracking",
  timeDelivery: "timeDelivery",
  orderDetails: "orderDetails",
  deliveryFee: "deliveryFee",
  deleteProduct: "deleteProduct",
  addItem: "addItem",
  viewProfile: "viewProfile",
  branchesOf: "branchesOf",
  minimumSelectedModifiers: "minimumSelectedModifiers",
  maximumSelectedModifiers: "maximumSelectedModifiers",
  emptyCart: "emptyCart",
  descriptionTime: "descriptionTime",
  arrivalTimeValidation: "arrivalTimeValidation",
  groupByCategories: "groupByCategories",
  integrations: "integrations",
  deliveryIntegration: "deliveryIntegration",
  paymentIntegration: "paymentIntegration",
  hubIntegration: "hubIntegration",
  notAvailableOnYourCountryOrIntegrationDependency:
    "notAvailableOnYourCountryOrIntegrationDependency",
  storeId: "storeId",
  integrationsPro: "integrationsPro",
  trackYourOrder: "trackYourOrder",
  orderNotFound: "orderNotFound",
  orderingOrTracking: "orderingOrTracking",
  ordering: "ordering",
  trackOrder: "trackOrder",
  primaryColor: "primaryColor",
  primaryColorTooltip: "primaryColorTooltip",
  secondaryColor: "secondaryColor",
  secondaryColorTooltip: "secondaryColorTooltip",
  chooseColor: "chooseColor",
  useCurrentLocation: "useCurrentLocation",
  orderingBranchClosed: "orderingBranchClosed",
  viewInvoices: "viewInvoices",
  viewInvoice: "viewInvoice",
  invoices: "invoices",
  amount: "amount",
  enjoyLoyalty: "enjoyLoyalty",
  loyaltyIntegration: "loyaltyIntegration",
  customerId: "customerId",
  customerIdPlaceHolder: "customerIdPlaceHolder",
  enjoyLoyaltyFailure: "enjoyLoyaltyFailure",
  ednaPayAdvertise: "ednaPayAdvertise",
  ednaPayAdvertiseCost: "ednaPayAdvertiseCost",
  chathubId: "chathubId",
  chathubKey: "chathubKey",
  chathubPassword: "chathubPassword",
  chathubStory: "chathubStory",
  chatBotIntegration: "chatBotIntegration",
  newOrderReceived: "newOrderReceived",
  goToClientPortal: "goToClientPortal",
  created: "created",
  updated: "updated",
  pixelId: "pixelId",
  onItsWay: "onItsWay",
  readyForPickUp: "readyForPickUp",
  readyToServe: "readyToServe",
  creditCardChargeAnowledge: "creditCardChargeAnowledge",
  parentCategory: "parentCategory",
  uiMode: "uiMode",
  compact: "compact",
  expanded: "expanded",
  seeMore: "seeMore",
  featured: "featured",
  disableBranchProductExclusionTT: "disableBranchProductExclusionTT",
  disableBranchProductExclusion: "disableBranchProductExclusion",
  showBanner: "showBanner",
  bannerImage: "bannerImage",
  hasSchedule: "hasSchedule",
  preview: "preview",
  endsIn: "endsIn",
  promotionHasEnded: "promotionHasEnded",
};

export default strings;

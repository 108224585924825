export enum UserType {
  Administrator = 0,
  Client = 1,
  ClientBranch = 2,
  User = 3,
}

export enum SupportedLanguage {
  en = "en",
  es = "es",
}

export type User = {
  id: string;
  email: string;
  type: UserType;
  isDisabled: boolean;
  restaurantId?: string;
  restaurantIds?: string[];
  branchId?: string;
  preferredLanguage: SupportedLanguage;
  restaurantName?: string;
  phoneNumber?: string;
  name?: string;
  referral?: string;
  info?: any;
  createdAt: Date;
  updatedAt: Date;
};

export enum CheckPosition {
  Left,
  Right
}
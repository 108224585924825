import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useParams,
} from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// @ts-ignore
import MessengerCustomerChat from "react-messenger-customer-chat";

import routes, { publicRoutes } from "./constants/routes";
import { mergeAndRemoveDuplicates } from "./utils/arrays";

import { NavBar } from "./components";
import { getPage } from "./pages";
import { useStore } from "./stores";
import { UserType } from "./models";

import UpdatePaymentModal from "./modals/UpdatePaymentModal";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

const AutoSelect = observer(({ Comp }: { Comp: React.ComponentClass }) => {
  const { restaurantId, branchId } = useParams<{
    restaurantId?: string;
    branchId?: string;
  }>();
  const { selectBranch, selectRestaurant, isLoading } = useStore("restaurants");
  const { user } = useStore("auth");

  const isAuthorized = useCallback(() => {
    if (user?.type === UserType.Administrator) {
      return true;
    }
    if (
      restaurantId &&
      mergeAndRemoveDuplicates(
        user?.restaurantId,
        undefined,
        user?.restaurantIds
      ).indexOf(restaurantId) > 1
    ) {
      return true;
    }
    return false;
  }, [restaurantId, user?.restaurantId, user?.restaurantIds, user?.type]);

  useEffect(() => {
    if (isLoading || !isAuthorized) {
      return;
    }

    const select = async () => {
      await selectRestaurant(restaurantId || null);
      await selectBranch(branchId || null);
    };

    select();
  }, [
    branchId,
    isAuthorized,
    isLoading,
    restaurantId,
    selectBranch,
    selectRestaurant,
  ]);

  if (!isAuthorized) {
    return <Redirect to={routes.notFound} />;
  }

  return <Comp />;
});

const ScrollReset = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const GuardedRoute = observer(({ render: Comp, guard, ...rest }: any) => {
  const auth = useStore("auth");

  if (!auth.initialized) return null;

  return (
    <Route
      {...rest}
      render={() =>
        auth.user || !guard ? (
          <AutoSelect Comp={Comp} />
        ) : (
          <Redirect to={routes.signIn} />
        )
      }
    />
  );
});

const AppRouter = observer(() => {
  const { user, initialized } = useStore("auth");

  if (!initialized) {
    return null;
  }

  let homeRoute = user ? routes.createRestaurant : routes.signIn;

  if (user && user.restaurantId) {
    homeRoute = routes.restaurantBranches.replace(
      ":restaurantId",
      user.restaurantId
    );
  }

  if (user && user.restaurantId && user.branchId) {
    homeRoute = routes.branchOrders
      .replace(":restaurantId", user.restaurantId)
      .replace(":branchId", user.branchId);
  }

  if (user?.type === UserType.Administrator) {
    homeRoute = routes.restaurants;
  }

  return (
    <React.Suspense fallback={null}>
      <Elements stripe={stripePromise}>
        <Router>
          <ScrollReset />
          <NavBar />
          <div className="section">
            <Switch>
              <Route key={homeRoute} exact path={routes.home}>
                <Redirect to={homeRoute} />
              </Route>
              {Object.values(routes).map((route) => (
                <GuardedRoute
                  key={route}
                  guard={!publicRoutes.includes(route)}
                  path={route}
                  exact
                  render={getPage(route)}
                />
              ))}
              <Redirect to={routes.notFound} />
            </Switch>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Slide}
          />
        </Router>
        <UpdatePaymentModal />
        <MessengerCustomerChat
          appId="265863377261049"
          pageId="200473257162452"
        />
      </Elements>
    </React.Suspense>
  );
});

export default AppRouter;

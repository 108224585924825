import { observable, action } from "mobx";
import { firestore } from "firebase/app";

import { DeliveryInfo, Order, OrderStatus } from "../models";
import { getFirestore } from "../utils/firestore";
import { logError } from "../services/logging";
import i18n from "../services/localization";

import strings from "../constants/strings";

import restaurants from "./restaurants";
import { FirebaseSubscriber } from "./utils";
import { compare } from "../utils/arrays";

const customId = require("custom-id");

class OrdersStore {
  private db: firestore.Firestore;
  private orderSubscriber?: FirebaseSubscriber;
  private ordersSubscriber?: FirebaseSubscriber;
  private pastOrdersSubscriber?: FirebaseSubscriber;
  private notificationSound = new Audio(process.env.REACT_APP_NEW_ORDER_SOUND);
  private beepSound = new Audio(
    "https://soundbible.com/mp3/Music_Box-Big_Daddy-1389738694.mp3"
  );

  @observable orders: Order[] = [];
  @observable pastOrders: Order[] = [];
  @observable order?: Order;
  @observable isLoading: boolean = false;

  togglePlay() {
    this.beepSound.play();
  }

  constructor() {
    try {
      this.notificationSound.load();
      this.beepSound.load();
    } catch (error) {
      logError(error);
    }
    this.db = getFirestore();
    this.togglePlay = this.togglePlay.bind(this);
  }

  @action.bound
  loadOrders = () => {
    if (
      !restaurants.selectedRestaurant ||
      !restaurants.selectedBranch ||
      this.ordersSubscriber?.id === restaurants.selectedBranch.id
    ) {
      return;
    }

    this.isLoading = true;
    let initial = true;

    if (this.ordersSubscriber) {
      this.ordersSubscriber.unsubscribe();
      this.ordersSubscriber = undefined;
      this.orders = [];
    }

    try {
      this.ordersSubscriber = {
        id: restaurants.selectedBranch.id,
        unsubscribe: this.db
          .collection("restaurants")
          .doc(restaurants.selectedRestaurant.id)
          .collection("branches")
          .doc(restaurants.selectedBranch.id)
          .collection("orders")
          .where("status", "in", [
            OrderStatus.AwaitingDelivery,
            OrderStatus.Preparing,
            OrderStatus.Received,
          ])
          .onSnapshot((snap) => {
            if (snap.empty) {
              return;
            }

            let docs = snap.docs.map((doc) => this.orderAdapter(doc.data()));

            if (
              docs.filter((x) => x.status === OrderStatus.Received).length > 0
            ) {
              this.beepSound.loop = true;
              this.beepSound.play();
            } else {
              this.beepSound.pause();
            }

            let temp = initial ? [] : [...this.orders];
            let playSound = false;

            snap.docChanges().forEach((change) => {
              const data = change.doc.data();
              switch (change.type) {
                case "added":
                  temp.push(this.orderAdapter(data));

                  if (!initial) {
                    playSound = true;
                  }
                  break;
                case "modified":
                  temp = temp.map((f) =>
                    f.id === data.id ? this.orderAdapter(data) : f
                  );
                  break;
                case "removed":
                  temp = temp.filter((f) => f.id !== data.id);
                  break;
              }
            });

            if (initial) {
              this.isLoading = false;
              initial = false;
            }
            this.orders = temp;

            if (playSound) {
              try {
                this.notificationSound.play();
                Notification.requestPermission()
                  .then(() => {
                    new Notification("EDNA Commerce", {
                      body: i18n.t(strings.newOrderReceived),
                    });
                  })
                  .catch((e) => logError(e));
                //Plays beep every 5 secods, 5 times
                // for (var i = 1; i < 7; i++) {
                //setTimeout(this.togglePlay, 3000*i);
                // }
              } catch (error) {
                logError(error);
              }
            }
          }, logError),
      };
    } catch (error) {
      logError(error);
      this.isLoading = false;
    }
  };

  @action.bound
  loadPastOrders = () => {
    if (
      !restaurants.selectedRestaurant ||
      !restaurants.selectedBranch ||
      this.pastOrdersSubscriber?.id === restaurants.selectedBranch.id
    ) {
      return;
    }

    this.isLoading = true;
    let initial = true;

    if (this.pastOrdersSubscriber) {
      this.pastOrdersSubscriber.unsubscribe();
      this.pastOrdersSubscriber = undefined;
      this.pastOrders = [];
    }

    try {
      this.pastOrdersSubscriber = {
        id: restaurants.selectedBranch.id,
        unsubscribe: this.db
          .collection("restaurants")
          .doc(restaurants.selectedRestaurant.id)
          .collection("branches")
          .doc(restaurants.selectedBranch.id)
          .collection("orders")
          .where("status", "in", [OrderStatus.Delivered, OrderStatus.Canceled])
          .orderBy("updatedAt", "desc")
          .limit(200)
          .onSnapshot((snap) => {
            if (snap.empty) {
              return;
            }

            let temp = initial ? [] : [...this.pastOrders];

            snap.docChanges().forEach((change) => {
              const data = change.doc.data();
              switch (change.type) {
                case "added":
                  temp.push(this.orderAdapter(data));
                  temp = temp.sort(compare((a) => a.name));
                  break;
                case "modified":
                  temp = temp.map((f) =>
                    f.id === data.id ? this.orderAdapter(data) : f
                  );
                  break;
                case "removed":
                  temp = temp.filter((f) => f.id !== data.id);
                  break;
              }
            });

            if (initial) {
              this.isLoading = false;
              initial = false;
            }
            this.pastOrders = temp;
          }, logError),
      };
    } catch (error) {
      logError(error);
      this.isLoading = false;
    }
  };

  @action.bound
  loadOrder = async (id: string) => {
    if (!restaurants.selectedRestaurant || !restaurants.selectedBranch) {
      return;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("branches")
        .doc(restaurants.selectedBranch.id)
        .collection("orders")
        .doc(id);

      const snap = await ref.get();
      const data = snap.data();

      this.order = data ? this.orderAdapter(data) : undefined;

      return !!this.order;
    } catch (error) {
      logError(error);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  searchOrderByReadableId = async (id: string) => {
    if (!restaurants.selectedRestaurant || this.orderSubscriber?.id === id) {
      return false;
    }

    if (this.orderSubscriber) {
      this.orderSubscriber.unsubscribe();
      this.orderSubscriber = undefined;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collectionGroup("orders")
        .where("readableId", "==", id);

      const snap = (await ref.get()).docs.find(
        (f) =>
          f.ref.parent.parent?.parent.parent?.id ===
          restaurants.selectedRestaurant?.id
      );

      if (!snap) {
        return false;
      }

      await restaurants.selectBranch(snap.ref.parent.parent?.id || "");

      this.orderSubscriber = {
        id,
        unsubscribe: snap.ref.onSnapshot((s) => {
          const data = s.data();
          this.order = data ? this.orderAdapter(data) : undefined;
        }),
      };

      return true;
    } catch (error) {
      logError(error);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  updateOrderStatus = async ({
    id,
    status,
    cancellationReason,
    preparationTime,
    deliveryInfo,
  }: {
    id: string;
    status: OrderStatus;
    cancellationReason?: string;
    preparationTime?: number;
    deliveryInfo?: DeliveryInfo;
  }) => {
    if (!restaurants.selectedRestaurant || !restaurants.selectedBranch) {
      return false;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("branches")
        .doc(restaurants.selectedBranch.id)
        .collection("orders")
        .doc(id);

      await ref.update({
        status,
        ...(preparationTime !== undefined ? { preparationTime } : {}),
        ...(cancellationReason ? { cancellationReason } : {}),
        ...(deliveryInfo ? { deliveryInfo } : {}),
        updatedAt: firestore.FieldValue.serverTimestamp() as any,
      });

      if (status === OrderStatus.Delivered || status === OrderStatus.Canceled) {
        this.orders = this.orders.filter((f) => f.id !== id);
      }

      return true;
    } catch (error) {
      logError(error);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  createOrder = async (
    order: Omit<Order, "id" | "readableId" | "createdAt" | "updatedAt">
  ) => {
    if (!restaurants.selectedRestaurant || !restaurants.selectedBranch) {
      return;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("branches")
        .doc(restaurants.selectedBranch.id)
        .collection("orders")
        .doc();

      await ref.set({
        ...order,
        id: ref.id,
        deliveryInfo: {
          ...order.deliveryInfo,
          location:
            order.deliveryInfo.location &&
            new firestore.GeoPoint(
              order.deliveryInfo.location.latitude,
              order.deliveryInfo.location.longitude
            ),
        },
        createdAt: firestore.FieldValue.serverTimestamp() as any,
        updatedAt: firestore.FieldValue.serverTimestamp() as any,
        readableId: customId({
          name: ref.id,
          randomLength: 2,
          lowerCase: false,
        }),
      });

      return ref.id;
    } catch (error) {
      logError(error);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  updateOrder = async (order: Omit<Order, "createdAt" | "updatedAt">) => {
    if (!restaurants.selectedRestaurant || !restaurants.selectedBranch) {
      return;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("branches")
        .doc(restaurants.selectedBranch.id)
        .collection("orders")
        .doc(order.id);

      await ref.update({
        ...order,
        deliveryInfo: {
          ...order.deliveryInfo,
          location:
            order.deliveryInfo.location &&
            new firestore.GeoPoint(
              order.deliveryInfo.location.latitude,
              order.deliveryInfo.location.longitude
            ),
        },
        updatedAt: firestore.FieldValue.serverTimestamp() as any,
      });

      if (this.order?.id === order.id) {
        this.order = { ...this.order, ...order };
      }

      return true;
    } catch (error) {
      logError(error);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  private orderAdapter = (data: firestore.DocumentData) =>
    ({
      ...data,
      createdAt: data.createdAt?.toDate() || new Date(),
      updatedAt: data.updatedAt?.toDate() || new Date(),
      deliveryInfo: {
        ...data.deliveryInfo,
        location: data.deliveryInfo.location && {
          latitude: data.deliveryInfo.location.latitude,
          longitude: data.deliveryInfo.location.longitude,
        },
      },
    } as Order);
}

export default new OrdersStore();

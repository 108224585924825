import React from "react";
import { useField, FieldConfig, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import strings from "../../constants/strings";

export const TimeSelect = <T, V>(
  props: FieldConfig<V> & {
    disabled?: boolean;
    label: string;
    options: T[];
    name: string;
    getValue?: (t: T) => V;
    getArrivaltime: () => string;
  }
) => {
  const [, { value, touched, error }, { setValue }] = useField(props);
  const { submitCount } = useFormikContext();
  const { t } = useTranslation();
  const isTouched = touched || submitCount > 0;

  const getValue = (t: T) =>
    t && props.getValue ? props.getValue(t) : ((t as unknown) as V);

  return (
    <div className="field">
      <label className="label">{props.label}</label>
      <div className="flexRow">
        {props.options.map((t, i) => (
          <div className="field is-flex-wrap-nowrap" key={i}>
            <input
              className="is-checkradio"
              style={{ marginRight: "0.5rem" }}
              id={"selectTime" + i}
              type="checkbox"
              value={Number(getValue(t))}
              onChange={(e) => setValue((t as unknown) as V)}
              name={props.name}
              checked={getValue(t) === value}
            />
            <label htmlFor={"selectTime" + i} style={{ marginRight: "0.5rem" }}>
              {t}
              {"min"}
            </label>
          </div>
        ))}
      </div>
      <div className="flexRow">
        <strong>{t(strings.arrivalTimeReady)}</strong>
        {": "}
        {props.getArrivaltime()}
      </div>
      {isTouched && !!error && <p className="help is-danger">{error}</p>}
    </div>
  );
};

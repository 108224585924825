import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useParams,
} from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";

import routes from "./constants/routes";

import { getPage } from "./pages";
import { useStore } from "./stores";

const AutoSelect = observer(({ Comp }: { Comp: any }) => {
  const { restaurantId, branchId } = useParams<{
    restaurantId?: string;
    branchId?: string;
  }>();
  const { selectBranch, selectRestaurant, isLoading } = useStore("restaurants");

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const select = async () => {
      if (restaurantId) await selectRestaurant(restaurantId);
      if (branchId) await selectBranch(branchId);
    };

    select();
  }, [branchId, isLoading, restaurantId, selectBranch, selectRestaurant]);

  return <Comp />;
});

const ScrollReset = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const WebViewApp = () => (
  <React.Suspense fallback={null}>
    <Router>
      <ScrollReset />
      <div className="section">
        <Switch>
          {Object.values(routes).map((route) => (
            <Route
              key={route}
              path={route}
              exact
              render={() => <AutoSelect Comp={getPage(route)} />}
            />
          ))}
          <Redirect to={routes.notFound} />
        </Switch>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
    </Router>
  </React.Suspense>
);

export default WebViewApp;

import { useState } from "react";
import clsx from "clsx";
import { useField, FieldConfig } from "formik";
import { ChromePicker } from "react-color";
import { Popover } from "react-tiny-popover";

import styles from "./ColorInput.module.scss";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  disableAlpha?: boolean;
  label?: string;
  tooltip?: string;
  placeholder?: string;
  useInputField?: boolean;
} & FieldConfig<string>;

export const ColorInput = (props: Props) => {
  const [field, meta, { setValue }] = useField(props);
  const [show, setShow] = useState(false);

  const inputProps = {
    ...field,
    className: clsx("input", {
      "is-danger": meta.touched && meta.error,
    }),
    type: props.type,
    placeholder: props.placeholder,
    disabled: props.disabled,
    readOnly: props.readOnly,
  };

  const picker = (
    <Popover
      isOpen={show}
      padding={10}
      onClickOutside={() => setShow(false)}
      content={() => (
        <ChromePicker
          disableAlpha={props.disableAlpha}
          color={meta.value}
          onChange={(c) => setValue(c.hex)}
        />
      )}
    >
      <div
        className={clsx("is-clickable", styles.swatch)}
        style={{ backgroundColor: meta.value }}
        onClick={() => setShow(!show)}
      ></div>
    </Popover>
  );

  return (
    <div className="field">
      {!!props.label && (
        <label className="label" htmlFor={props.name}>
          {props.label}
          {!!props.tooltip && (
            <p className="label is-small">({props.tooltip})</p>
          )}
        </label>
      )}
      <div className="control has-icons-left">
        {props.useInputField ? (
          <>
            <input
              id={props.name}
              {...inputProps}
              readOnly
              onClick={() => setShow(!show)}
            />
            <span className={clsx("icon is-left", styles.icon)}>{picker}</span>
          </>
        ) : (
          picker
        )}
      </div>
      {meta.touched && !!meta.error && (
        <p className="help is-danger">{meta.error}</p>
      )}
    </div>
  );
};

import { analytics } from "firebase/app";

export const logError = (error: any, extra?: any) => {
  if (process.env.NODE_ENV === "development") {
    console.error(error, extra);
  } else {
    let description = typeof error === "string" ? error : error.message;
    analytics().logEvent("exception", {
      exDescription: description ? description.substring(0, 150) : "",
      exFatal: false,
    });
  }
};

import { observable, action } from "mobx";
import { firestore } from "firebase/app";

import { DeliveryInfo, Order } from "../models";
import { getFirestore } from "../utils/firestore";
import { logError } from "../services/logging";

import { FirebaseSubscriber } from "./utils";
import { compare } from "../utils/arrays";
import auth from "./auth";

class HistoryStore {
  private db: firestore.Firestore;
  private deliveryInfosSubscriber?: FirebaseSubscriber;
  private pastOrdersSubscriber?: FirebaseSubscriber;

  @observable deliveryInfos: DeliveryInfo & { id: string }[] = [];
  @observable pastOrders: Order[] = [];
  @observable isLoading: boolean = false;

  constructor() {
    this.db = getFirestore();
  }

  @action.bound
  loadDeliveryInfos = (userId: string = auth.user?.id || "") => {
    if (userId || this.deliveryInfosSubscriber?.id === userId) {
      return;
    }

    this.isLoading = true;
    let initial = true;

    if (this.deliveryInfosSubscriber) {
      this.deliveryInfosSubscriber.unsubscribe();
      this.deliveryInfosSubscriber = undefined;
      this.deliveryInfos = [];
    }

    try {
      this.deliveryInfosSubscriber = {
        id: userId,
        unsubscribe: this.db
          .collection("users")
          .doc(userId)
          .collection("recentAddresses")
          .onSnapshot((snap) => {
            if (snap.empty) {
              return;
            }

            let temp = initial ? [] : [...this.deliveryInfos];

            snap.docChanges().forEach((change) => {
              const data = change.doc.data();
              switch (change.type) {
                case "added":
                  temp.push({
                    ...this.deliveryInfoAdapter(data),
                    id: change.doc.id,
                  });
                  break;
                case "modified":
                  temp = temp.map((f) =>
                    f.id === data.id
                      ? {
                          ...this.deliveryInfoAdapter(data),
                          id: change.doc.id,
                        }
                      : f
                  );
                  break;
                case "removed":
                  temp = temp.filter((f) => f.id !== data.id);
                  break;
              }
            });

            if (initial) {
              this.isLoading = false;
              initial = false;
            }
            this.deliveryInfos = temp;
          }, logError),
      };
    } catch (error) {
      logError(error);
      this.isLoading = false;
    }
  };

  @action.bound
  loadPastOrders = (userId: string = auth.user?.id || "") => {
    if (!userId || this.pastOrdersSubscriber?.id === userId) {
      return;
    }

    this.isLoading = true;
    let initial = true;

    if (this.pastOrdersSubscriber) {
      this.pastOrdersSubscriber.unsubscribe();
      this.pastOrdersSubscriber = undefined;
      this.pastOrders = [];
    }

    try {
      this.pastOrdersSubscriber = {
        id: userId,
        unsubscribe: this.db
          .collection("users")
          .doc(userId)
          .collection("orders")
          .orderBy("updatedAt", "desc")
          .limit(200)
          .onSnapshot((snap) => {
            if (snap.empty) {
              return;
            }

            let temp = initial ? [] : [...this.pastOrders];

            snap.docChanges().forEach((change) => {
              const data = change.doc.data();
              switch (change.type) {
                case "added":
                  temp.push(this.orderAdapter(data));
                  temp = temp.sort(compare((a) => a.name));
                  break;
                case "modified":
                  temp = temp.map((f) =>
                    f.id === data.id ? this.orderAdapter(data) : f
                  );
                  break;
                case "removed":
                  temp = temp.filter((f) => f.id !== data.id);
                  break;
              }
            });

            if (initial) {
              this.isLoading = false;
              initial = false;
            }
            this.pastOrders = temp;
          }, logError),
      };
    } catch (error) {
      logError(error);
      this.isLoading = false;
    }
  };

  private deliveryInfoAdapter = (data: firestore.DocumentData) =>
    ({
      ...data,
      location: data.location && {
        latitude: data.location.latitude,
        longitude: data.location.longitude,
      },
    } as DeliveryInfo);

  private orderAdapter = (data: firestore.DocumentData) =>
    ({
      ...data,
      createdAt: data.createdAt?.toDate() || new Date(),
      updatedAt: data.updatedAt?.toDate() || new Date(),
      deliveryInfo: {
        ...data.deliveryInfo,
        location: data.deliveryInfo.location && {
          latitude: data.deliveryInfo.location.latitude,
          longitude: data.deliveryInfo.location.longitude,
        },
      },
    } as Order);
}

export default new HistoryStore();

import { useEffect, useState } from "react";
import { loadScript } from "../utils/scripts";

const useGoogleMapsLib = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?v=3.46&key=${
        (window as any).firebaseAppConfig.apiKey
      }&libraries=geometry,places`,
      "google-maps-js",
      () => setReady(true)
    );
  }, []);

  return { ready };
};

export default useGoogleMapsLib;

import auth from "./auth";
import settings from "./settings";
import restaurants from "./restaurants";
import menu from "./menu";
import orders from "./orders";
import history from "./history";

const stores = {
  auth,
  settings,
  restaurants,
  menu,
  orders,
  history,
};

export function useStore<T extends keyof typeof stores>(
  name: T
): typeof stores[T] {
  return stores[name];
}

import React, { useCallback } from "react";
import { useField, FieldConfig } from "formik";
import { useTranslation } from "react-i18next";

import strings from "../../constants/strings";

export const ImageField = (
  props: FieldConfig<File> & {
    disabled?: boolean;
    label?: string;
  }
) => {
  const { t } = useTranslation();
  const [, { value }, { setValue }] = useField(props);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.currentTarget.files) return;
      const file = e.currentTarget.files[0];
      setValue(file);
    },
    [setValue]
  );

  return (
    <div className="field">
      <label className="label">{props.label}</label>
      <div className="file hast-name">
        <label className="file-label">
          <input
            value=""
            onChange={handleChange}
            type="file"
            className="file-input"
            id="image-upload"
            accept="image/*"
            disabled={props.disabled}
          />
          <span className="file-cta">
            <span className="file-icon">
              <i className="fas fa-upload"></i>
            </span>
            <span className="file-label">{t(strings.file)}</span>
          </span>
          {!!value && <span className="file-name">{value.name}</span>}
        </label>
      </div>
    </div>
  );
};

import { observable, action } from "mobx";
import { create, persist } from "mobx-persist";
import { auth } from "firebase/app";
import i18next from "i18next";
import moment from "moment";
import "moment/locale/es";

import { SupportedLanguage } from "../models";
import { getFirestore } from "../utils/firestore";

class SettingsStore {
  @persist @observable darkMode: "auto" | "activated" | "deactivated" = "auto";
  @persist @observable authPersist: boolean = true;
  @persist @observable language: SupportedLanguage = SupportedLanguage.en;

  constructor() {
    i18next.on("languageChanged", (lng) => {
      moment.locale(lng);
    });
  }

  @action.bound
  setDarkMode = (darkMode: "auto" | "activated" | "deactivated") => {
    this.darkMode = darkMode;
  };

  @action.bound
  setAuthPersist = async (authPersist: boolean) => {
    this.authPersist = authPersist;
    await auth().setPersistence(
      authPersist ? auth.Auth.Persistence.LOCAL : auth.Auth.Persistence.SESSION
    );
  };

  @action.bound
  setLanguage = async (language: SupportedLanguage) => {
    this.language = language;
    i18next.changeLanguage(language);

    const currUser = auth().currentUser;

    if (!currUser) {
      return;
    }

    await getFirestore().collection("users").doc(currUser.uid).update({
      preferredLanguage: language,
    });
  };
}

const settings = new SettingsStore();

export default settings;

create()("settings", settings);

export const makeRandomColor = () => {
  var c = "";
  while (c.length < 6) {
    c += Math.random().toString(16).substr(-6).substr(-1);
  }
  return "#" + c;
};

export function lightOrDark(color?: string) {
  // Variables for red, green, blue values
  let r: any = 0,
    g: any = 0,
    b: any = 0,
    hsp: any = 0;

  if (!color) {
    return null;
  }

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    const c = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    if (!c) {
      return null;
    }

    r = c[1];
    g = c[2];
    b = c[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    const c = +(
      "0x" + color.slice(1).replace((color.length < 5 && /./g) || "", "$&$&")
    );

    r = c >> 16;
    g = (c >> 8) & 255;
    b = c & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "light";
  } else {
    return "dark";
  }
}

export const loadScript = (
  url: string,
  key: string = url,
  callback?: () => void
) => {
  const googleScript = window.document.getElementById(key);
  if (!googleScript) {
    const script = window.document.createElement("script");
    script.src = url;
    script.id = key;
    document.body.appendChild(script);
    script.onload = callback || null;
  } else if (callback) {
    callback();
  }
};

import { useCallback, useState } from "react";
import clsx from "clsx";

import { LocalImage } from "../localImage/LocalImage";
import { CategoryPromotion, Schedule } from "../../models";

import styles from "./TimedPromotion.module.scss";
import { TimedPromotion } from "./TimedPromotion";

export const TimedPromotionCard = ({
  image,
  schedule,
  tz,
  promotion,
  onClick,
  className,
}: {
  className?: string;
  schedule: Schedule;
  image?: File | string;
  promotion?: Omit<CategoryPromotion, "bannerImage"> & {
    bannerImage?: File | string;
  };
  tz: string;
  onClick?: () => void;
}) => {
  const [timedOut, setTimedOut] = useState(false);

  const img =
    promotion?.bannerImage ||
    image ||
    process.env.REACT_APP_CATEGORY_IMAGE_DEFAULT;

  const handleTimeout = useCallback(() => {
    setTimedOut(true);
  }, []);

  return (
    <div
      className={clsx(
        "is-flex is-flex-direction-column",
        styles.card,
        {
          [styles.cardDisabled]: timedOut,
          "is-clickable": !!onClick,
        },
        className
      )}
      onClick={onClick}
    >
      <figure className="image is-16by9 mb-1">
        {img instanceof File ? (
          <LocalImage file={img} alt="promo" className="has-rounded-corners" />
        ) : (
          <img src={img} alt="promo" className="has-rounded-corners" />
        )}
      </figure>
      <TimedPromotion schedule={schedule} tz={tz} onTimeout={handleTimeout} />
    </div>
  );
};

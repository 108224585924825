import React from "react";
// import { TFunctionResult } from "i18next";
import clsx from "clsx";
import { FieldConfig } from "formik";

type Props = {
  label?: string;
  tooltip?: string;
  isOk?: boolean;
  link?: { text: string; url: string };
} & FieldConfig<string>;

export function Checklist(props: Props) {
  return (
    <div className="field ml-3">
      <div
        className="has-tooltip-arrow text-center is-inline-block"
        data-tooltip={props.tooltip}
      >
        {props.isOk && (
          <span style={{ color: "Green" }}>
            <i className={clsx("fas", "fa-check-circle is-left")}></i>
          </span>
        )}
        {!props.isOk && (
          <span style={{ color: "Tomato", marginLeft: "2px" }}>
            <i className={clsx("fas", "fa-times is-left")}></i>
          </span>
        )}
        <label className="ml-2">{props.label}</label>
        {props.link && (
          <>
            &nbsp;
            <a href={props.link.url} target="_blank" rel="noopener noreferrer">
              {props.link.text}
            </a>
          </>
        )}
      </div>
    </div>
  );
}

import { firestore } from "firebase/app";

export const getFirestore = () => {
  const fn = firestore();
  fn.settings({ ignoreUndefinedProperties: true });
  
  if (process.env.NODE_ENV === "development") {
    fn.settings({
      host: "localhost:8080",
      ssl: false,
    });
  }

  return fn;
};

import React, { useCallback, useState } from "react";
import { useField, FieldConfig, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import get from "lodash.get";

import strings from "../../constants/strings";

import { Location } from "../../models";
import { Modal } from "../../components";

import { Search } from "./Search";

export const PlaceField = (
  props: FieldConfig<Location> & {
    disabled?: boolean;
    label?: string;
    countryCode?: string;
    addressFieldName?: string;
    zoneFieldName?: string;
    isFullScreen?: boolean;
  }
) => {
  const { t } = useTranslation();
  const [, meta, { setValue }] = useField(props);
  const { setFieldValue, values } = useFormikContext<any>();
  const [showSearch, setShowSearch] = useState(false);

  const handleOnSelect = useCallback(
    (
      value: Location & {
        address: string;
        city: string;
        state: string;
        postalCode: string;
      }
    ) => {
      setValue({
        latitude: value.latitude,
        longitude: value.longitude,
      });

      if (props.addressFieldName) {
        setFieldValue(props.addressFieldName, value.address);
      }

      if (props.zoneFieldName) {
        setFieldValue(`${props.zoneFieldName}.city`, value.city);
        setFieldValue(`${props.zoneFieldName}.postalCode`, value.postalCode);
        setFieldValue(`${props.zoneFieldName}.state`, value.state);
      }

      setShowSearch(false);
    },
    [props.addressFieldName, props.zoneFieldName, setFieldValue, setValue]
  );

  return (
    <div className="field">
      <label className="label">{props.label}</label>
      <div
        className="control has-icons-left is-touchable"
        style={{ cursor: "text" }}
        onClick={() => setShowSearch(true)}
      >
        <input
          className={clsx("input", {
            "is-danger": meta.touched && meta.error,
          })}
          style={{ pointerEvents: "none" }}
          disabled={props.disabled}
          value={
            (props.addressFieldName && get(values, props.addressFieldName)) ||
            ""
          }
          placeholder={t(strings.typeAddress)}
          onChange={() => {}}
        />
        <span className="icon is-small is-left">
          <i className="fas fa-location-arrow"></i>
        </span>
      </div>
      {meta.touched && !!meta.error && (
        <p className="help is-danger">{meta.error}</p>
      )}
      {showSearch && (
        <Modal
          isOpen
          isFullScreen={props.isFullScreen}
          title={t(strings.search)}
          onClose={() => setShowSearch(false)}
        >
          <Search
            onSelect={handleOnSelect}
            countryCode={props.countryCode}
            isFullScreen={props.isFullScreen}
          />
        </Modal>
      )}
    </div>
  );
};

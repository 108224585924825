/**
 * Self-adjusting interval to account for drifting
 *
 * @param {function} workFunc  Callback containing the work to be done
 *                             for each interval
 * @param {int}      interval  Interval speed (in milliseconds)
 * @param {function} errorFunc (Optional) Callback to run if the drift
 *                             exceeds interval
 */
export default class Timer {
  expected = 0;
  timeout?: NodeJS.Timeout;
  interval: number;
  workFunc: (bag: { stop: () => void }) => void;
  errorFunc?: () => void;

  constructor(
    interval: number,
    workFunc: (bag: { stop: () => void }) => void,
    errorFunc?: () => void
  ) {
    this.interval = interval;
    this.workFunc = workFunc;
    this.errorFunc = errorFunc;
  }

  start = () => {
    this.expected = Date.now() + this.interval;
    this.timeout = setTimeout(this.step, this.interval);
  };

  stop = () => {
    if (this.timeout) clearTimeout(this.timeout);
  };

  step = () => {
    var drift = Date.now() - this.expected;
    if (drift > this.interval) {
      // You could have some default stuff here too...
      if (this.errorFunc) this.errorFunc();
    }
    this.workFunc({ stop: this.stop });
    this.expected += this.interval;
    this.timeout = setTimeout(this.step, Math.max(0, this.interval - drift));
  };
}

import React from "react";
import clsx from "clsx";

type Props = {
  isOpen: boolean;
  title?: string;
  children?: any;
  primaryText?: string;
  primaryLoading?: boolean;
  cancelButton?: string;
  isFullScreen?: boolean;
  isNonScrollable?: boolean;
  onClose?: () => void;
  onPrimary?: () => void;
  onSecondary?: () => void;
};

export const ConfirmationModal = ({
  isOpen,
  title,
  children,
  primaryText,
  primaryLoading,
  cancelButton,
  isFullScreen,
  isNonScrollable,
  onClose,
  onPrimary,
  onSecondary,
}: Props) => (
  <div
    className={clsx("modal", {
      "is-active": isOpen,
      "is-fullscreen": isFullScreen,
      "no-scrollable": isNonScrollable,
    })}
  >
    <div className="modal-background" />
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title has-ellipsis-if-long has-text-weight-semibold is-size-5 pb-4">
          {title}
        </p>
        {!!onClose && (
          <div className="pb-4">
            {" "}
            <button className="delete" aria-label="close" onClick={onClose} />
          </div>
        )}
      </header>
      <>dadasadadsa</>
      {children && <section className="modal-card-body">{children}</section>}

      {!!primaryText && (
        <footer className="modal-card-foot">
          {!!primaryText && (
            <>
              <button
                className={clsx("button", "is-primary", {
                  "is-loading": primaryLoading,
                })}
                onClick={onPrimary}
              >
                {primaryText}
              </button>
              {cancelButton && (
                <button className="button is-danger" onClick={onClose}>
                  {cancelButton}
                </button>
              )}
            </>
          )}
        </footer>
      )}
    </div>
  </div>
);

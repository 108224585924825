import React from "react";
import { TFunctionResult } from "i18next";
import clsx from "clsx";
import { useField, FieldConfig } from "formik";

type Props = {
  label: React.ReactChild | TFunctionResult;
  icon?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  useTextArea?: boolean;
  tooltip?: string;
} & FieldConfig<string>;

export const Field = (props: Props) => {
  const [field, meta] = useField(props);

  const label = typeof props.label === "function" ? props.label() : props.label;
  const renderCheckbox = () => (
    <div className="field">
      <div className="control">
        <label
          className={clsx("checkbox", {
            "is-danger": meta.touched && meta.error,
          })}
        >
          <input type="checkbox" {...field} disabled={props.disabled} /> {label}
        </label>
      </div>
      {meta.touched && !!meta.error && (
        <p className="help is-danger">{meta.error}</p>
      )}
    </div>
  );

  const inputProps = {
    ...field,
    className: clsx("input", {
      "is-danger": meta.touched && meta.error,
    }),
    type: props.type,
    placeholder: props.placeholder,
    disabled: props.disabled,
    readOnly: props.readOnly,
    tooltip: props.tooltip,
  };

  return props.type === "checkbox" ? (
    renderCheckbox()
  ) : (
    <div className="field">
      <label className="label" htmlFor={props.name}>
        {label}
        {!!props.tooltip && <p className="label is-small">({props.tooltip})</p>}
      </label>
      <div className={clsx("control", { "has-icons-left": !!props.icon })}>
        {props.useTextArea ? (
          <textarea {...inputProps} />
        ) : (
          <input id={props.name} {...inputProps} />
        )}

        {!!props.icon && (
          <span className="icon is-small is-left">
            <i className={clsx("fas", props.icon)}></i>
          </span>
        )}
      </div>
      {meta.touched && !!meta.error && (
        <p className="help is-danger">{meta.error}</p>
      )}
    </div>
  );
};

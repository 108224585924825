import { observable, action } from "mobx";
import { firestore, functions } from "firebase/app";

import {
  Restaurant,
  Branch,
  PlanType,
  PlanStatus,
  EDNAPayLinkResponse,
  AvailableIntegrations,
  UserType,
  PlanInvoice,
} from "../models";
import { getFirestore } from "../utils/firestore";
import { logError } from "../services/logging";
import firebase from "../services/firebase";
import { uploadImage, FirebaseSubscriber } from "./utils";
import { compare } from "../utils/arrays";

import auth from "./auth";

class RestaurantsStore {
  private db: firestore.Firestore;
  private selectedRestaurantSubscriber?: FirebaseSubscriber;
  private selectedBranchSubscriber?: FirebaseSubscriber;
  private restaurantsSubscriber?: FirebaseSubscriber;
  private branchesSubscriber?: FirebaseSubscriber;

  @observable selectedRestaurant?: Restaurant;
  @observable selectedBranch?: Branch;
  @observable restaurants: Restaurant[] = [];
  @observable branches: Branch[] = [];
  @observable isLoading: boolean = false;

  constructor() {
    this.db = getFirestore();
  }

  @action.bound
  loadRestaurants = (restaurantIds?: string[]) => {
    if (this.restaurantsSubscriber?.id === auth.user?.id) {
      return;
    }

    this.isLoading = true;
    let initial = true;

    if (this.restaurantsSubscriber) {
      this.restaurantsSubscriber.unsubscribe();
      this.restaurantsSubscriber = undefined;
      this.restaurants = [];
    }

    try {
      this.queryRestaurant(initial, restaurantIds);
    } catch (error) {
      logError(error);
      this.isLoading = false;
    }
  };

  @action.bound
  queryRestaurant = (initial: boolean, restaurantIds?: string[]) => {
    if (restaurantIds && restaurantIds?.length > 0) {
      this.restaurantsSubscriber = {
        id: auth.user?.id || "",
        unsubscribe: this.db
          .collection("restaurants")
          .where("id", "in", restaurantIds)
          .orderBy("name")
          .onSnapshot((snap) => {
            this.snapshot(snap, initial);
          }, logError),
      };
    } else {
      //Only administrators
      if (auth.user?.type === UserType.Administrator) {
        this.restaurantsSubscriber = {
          id: auth.user?.id || "",
          unsubscribe: this.db
            .collection("restaurants")
            .orderBy("name")
            .onSnapshot((snap) => {
              this.snapshot(snap, initial);
            }, logError),
        };
      }
    }
  };

  @action.bound
  snapshot = (
    snap: firestore.QuerySnapshot<firestore.DocumentData>,
    initial: boolean
  ) => {
    if (snap.empty) {
      return;
    }

    let temp = initial ? [] : [...this.restaurants];

    snap.docChanges().forEach((change) => {
      const data = change.doc.data();
      switch (change.type) {
        case "added":
          temp.push(this.restaurantAdapter(data));
          temp = temp.sort(compare((a) => a.name));
          break;
        case "modified":
          temp = temp.map((f) =>
            f.id === data.id ? this.restaurantAdapter(data) : f
          );
          break;
        case "removed":
          temp = temp.filter((f) => f.id !== data.id);
          break;
      }
    });

    if (initial) {
      this.isLoading = false;
      initial = false;
    }
    this.restaurants = temp;
  };

  @action.bound
  loadBranches = () => {
    if (
      !this.selectedRestaurant ||
      this.branchesSubscriber?.id === this.selectedRestaurant.id
    ) {
      return;
    }

    this.isLoading = true;
    let initial = true;

    if (this.branchesSubscriber) {
      this.branchesSubscriber.unsubscribe();
      this.branchesSubscriber = undefined;
      this.branches = [];
    }

    try {
      this.branchesSubscriber = {
        id: this.selectedRestaurant.id,
        unsubscribe: this.db
          .collection("restaurants")
          .doc(this.selectedRestaurant.id)
          .collection("branches")
          .orderBy("name")
          .onSnapshot((snap) => {
            if (snap.empty) {
              return;
            }

            let temp = initial ? [] : initial ? [] : [...this.branches];

            snap.docChanges().forEach((change) => {
              const data = change.doc.data();
              switch (change.type) {
                case "added":
                  temp.push(this.branchAdapter(data));
                  temp = temp.sort(compare((a) => a.name));
                  break;
                case "modified":
                  temp = temp.map((f) =>
                    f.id === data.id ? this.branchAdapter(data) : f
                  );
                  break;
                case "removed":
                  temp = temp.filter((f) => f.id !== data.id);
                  break;
              }
            });

            if (initial) {
              this.isLoading = false;
              initial = false;
            }
            this.branches = temp;
          }, logError),
      };
    } catch (error) {
      logError(error);
      this.isLoading = false;
    }
  };

  @action.bound
  createRestaurant = async ({
    logo,
    ...data
  }: Omit<Restaurant, "id" | "logo" | "createdAt" | "updatedAt" | "plan"> & {
    logo?: File;
  }) => {
    this.isLoading = true;

    try {
      const ref = this.db.collection("restaurants").doc();
      const restaurant: Restaurant = {
        ...data,
        id: ref.id,
        createdAt: firestore.FieldValue.serverTimestamp() as any,
        updatedAt: firestore.FieldValue.serverTimestamp() as any,
        plan: {
          type: PlanType.Basic,
          allowedBranches: 1,
          status: PlanStatus.Active,
          updatedAt: firestore.FieldValue.serverTimestamp() as any,
        },
      };

      if (logo) {
        const img = await uploadImage(logo, ref.id);
        if (img) restaurant.logo = img;
      }

      await ref.set(restaurant);
      await auth.setUsersRestaurantId(ref.id);

      return true;
    } catch (error) {
      logError(error);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  createBranch = async ({
    otherPaymentMethodInfo: { description, image },
    ...data
  }: Omit<
    Branch,
    "id" | "createdAt" | "updatedAt" | "otherPaymentMethodInfo"
  > & {
    otherPaymentMethodInfo: {
      description?: string;
      image?: File;
    };
  }) => {
    if (!this.selectedRestaurant) {
      return;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(this.selectedRestaurant.id)
        .collection("branches")
        .doc();
      const branch: Branch = {
        ...data,
        location:
          data.location &&
          new firestore.GeoPoint(
            data.location.latitude,
            data.location.longitude
          ),
        otherPaymentMethodInfo: { description },
        id: ref.id,
        createdAt: firestore.FieldValue.serverTimestamp() as any,
        updatedAt: firestore.FieldValue.serverTimestamp() as any,
      };

      if (image) {
        const img = await uploadImage(image, ref.id + "_opm");
        if (img) branch.otherPaymentMethodInfo = { description, image: img };
      }

      await ref.set(branch);

      return true;
    } catch (error) {
      logError(error);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  updateRestaurant = async ({
    logo,
    createdAt,
    ...data
  }: Omit<Restaurant, "logo" | "updatedAt"> & {
    logo?: File;
  }) => {
    this.isLoading = true;

    try {
      const ref = this.db.collection("restaurants").doc(data.id);
      const restaurant = {
        ...data,
        updatedAt: firestore.FieldValue.serverTimestamp(),
      };

      if (logo) {
        const img = await uploadImage(logo, ref.id);
        if (img) {
          (restaurant as any).logo = img;
        }
      }

      await ref.update(restaurant);
      await auth.setUsersRestaurantId(ref.id);
      const { updatedAt, ...d } = restaurant;

      if (this.selectedRestaurant?.id === data.id) {
        this.selectedRestaurant = { ...this.selectedRestaurant, ...d };
      }

      return true;
    } catch (error) {
      logError(error);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  updateBranch = async ({
    createdAt,
    managers,
    otherPaymentMethodInfo: { description, image },
    ...data
  }: Omit<Branch, "updatedAt" | "managers" | "otherPaymentMethodInfo"> & {
    managers: { email: string; password?: string }[];
    otherPaymentMethodInfo: {
      description?: string;
      image?: File;
    };
  }) => {
    if (!this.selectedRestaurant) {
      return;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(this.selectedRestaurant.id)
        .collection("branches")
        .doc(data.id);
      const branch = {
        ...data,
        location:
          data.location &&
          new firestore.GeoPoint(
            data.location.latitude,
            data.location.longitude
          ),
        "otherPaymentMethodInfo.description": description,
        updatedAt: firestore.FieldValue.serverTimestamp() as any,
        managers: managers.map((m) => m.email),
      };

      if (
        managers.length !== this.selectedBranch?.managers?.length ||
        !managers.every(
          (e) => this.selectedBranch?.managers?.includes(e.email) && !e.password
        )
      ) {
        const { data } = await functions().httpsCallable(
          "handleBranchManagers"
        )({
          restaurantId: this.selectedRestaurant.id,
          branchId: this.selectedBranch?.id,
          managers,
        });

        if (data.error) {
          throw data.error;
        }
      }

      if (image) {
        const img = await uploadImage(image, ref.id + "_opm");
        if (img) (branch as any)["otherPaymentMethodInfo.image"] = img;
      }

      await ref.update(branch);
      const { updatedAt, ...d } = branch;

      if (this.selectedBranch?.id === data.id) {
        this.selectedBranch = { ...this.selectedBranch, ...d };
      }

      return true;
    } catch (error) {
      logError(error);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  selectRestaurant = async (id: string | null) => {
    if (!id) {
      this.selectedRestaurant = undefined;

      if (this.selectedRestaurantSubscriber) {
        this.selectedRestaurantSubscriber.unsubscribe();
        this.selectedRestaurantSubscriber = undefined;
      }
      return;
    }

    if (
      this.selectedRestaurant?.id === id ||
      this.selectedRestaurantSubscriber?.id === id
    ) {
      return;
    }

    if (this.selectedRestaurantSubscriber) {
      this.selectedRestaurantSubscriber.unsubscribe();
      this.selectedRestaurantSubscriber = undefined;
    }

    this.isLoading = true;

    return new Promise((resolve) => {
      let initial = true;
      this.selectedRestaurantSubscriber = {
        id,
        unsubscribe: this.db
          .collection("restaurants")
          .doc(id)
          .onSnapshot((snap) => {
            const data = snap.data();

            if (!data) {
              return;
            }

            this.selectedRestaurant = this.restaurantAdapter(data);
            if (initial) {
              initial = false;
              this.isLoading = false;
              resolve(this.selectedRestaurant);
            }
          }, logError),
      };
    });
  };

  @action.bound
  selectBranch = async (id: string | null) => {
    if (!id) {
      this.selectedBranch = undefined;

      if (this.selectedBranchSubscriber) {
        this.selectedBranchSubscriber.unsubscribe();
        this.selectedBranchSubscriber = undefined;
      }
      return;
    }

    if (
      !this.selectedRestaurant ||
      this.selectedBranch?.id === id ||
      this.selectedBranchSubscriber?.id === id
    ) {
      return;
    }

    if (this.selectedBranchSubscriber) {
      this.selectedBranchSubscriber.unsubscribe();
      this.selectedBranchSubscriber = undefined;
    }

    this.isLoading = true;

    return new Promise((resolve) => {
      if (!this.selectedRestaurant) {
        return;
      }

      let initial = true;
      this.selectedBranchSubscriber = {
        id,
        unsubscribe: this.db
          .collection("restaurants")
          .doc(this.selectedRestaurant.id)
          .collection("branches")
          .doc(id)
          .onSnapshot((snap) => {
            const data = snap.data();

            if (!data) {
              return;
            }

            this.selectedBranch = this.branchAdapter(data);
            if (initial) {
              initial = false;
              this.isLoading = false;
              resolve(this.selectedBranch);
            }
          }, logError),
      };
    });
  };

  @action.bound
  deactivateBranch = async (id: string) => {
    if (!this.selectedRestaurant) {
      return;
    }

    await this.db
      .collection("restaurants")
      .doc(this.selectedRestaurant.id)
      .collection("branches")
      .doc(id)
      .update({ disabled: true });

    if (this.selectedBranch?.id === id) {
      this.selectedBranch = { ...this.selectedBranch, disabled: true };
    }
  };

  @action.bound
  activateBranch = async (id: string) => {
    if (!this.selectedRestaurant) {
      return;
    }

    await this.db
      .collection("restaurants")
      .doc(this.selectedRestaurant.id)
      .collection("branches")
      .doc(id)
      .update({ disabled: false });

    if (this.selectedBranch?.id === id) {
      this.selectedBranch = { ...this.selectedBranch, disabled: false };
    }
  };

  @action.bound
  setPlan = async (
    type: PlanType,
    allowedBranches: number,
    paymentMethodId: string
  ) => {
    if (!this.selectedRestaurant) {
      return false;
    }

    this.isLoading = true;

    try {
      const { data } = await functions().httpsCallable("setPlan")({
        restaurantId: this.selectedRestaurant.id,
        allowedBranches,
        paymentMethodId,
        type,
      });

      if (data.error) {
        throw data.error;
      }
    } catch (error) {
      logError(error);
      return false;
    } finally {
      this.isLoading = false;
    }

    return true;
  };

  @action.bound
  updatePlan = async (type: PlanType, allowedBranches: number) => {
    if (!this.selectedRestaurant) {
      return false;
    }

    this.isLoading = true;

    try {
      const { data } = await functions().httpsCallable("updatePlan")({
        restaurantId: this.selectedRestaurant.id,
        allowedBranches,
        type,
      });

      if (data.error) {
        throw data.error;
      }
    } catch (error) {
      logError(error);
      return false;
    } finally {
      this.isLoading = false;
    }

    return true;
  };

  @action.bound
  updatePaymentMethod = async (paymentMethodId: string) => {
    if (!this.selectedRestaurant) {
      return false;
    }

    this.isLoading = true;

    try {
      const { data } = await functions().httpsCallable("updatePaymentMethod")({
        customerId: this.selectedRestaurant.plan.stripeId,
        paymentMethodId,
      });

      if (data.error) {
        throw data.error;
      }
    } catch (error) {
      logError(error);
      return false;
    } finally {
      this.isLoading = false;
    }

    return true;
  };

  @action.bound
  linkEdnaPay = async (ednaPayLinkResponse?: EDNAPayLinkResponse) => {
    if (!this.selectedRestaurant) {
      return false;
    }

    if (!ednaPayLinkResponse) {
      return false;
    }

    this.isLoading = true;

    try {
      const { data } = await functions().httpsCallable("linkEdnaPay")({
        restaurantId: this.selectedRestaurant.id,
        ednaPayLinkResponse: ednaPayLinkResponse,
      });

      if (data.error) {
        throw data.error;
      }
    } catch (error) {
      logError(error);
      return false;
    } finally {
      this.isLoading = false;
    }

    return true;
  };

  getInvoices = async (): Promise<PlanInvoice[]> => {
    if (!this.selectedRestaurant) {
      return [];
    }

    try {
      const res = await firebase.functions().httpsCallable("listInvoices")({
        customer: this.selectedRestaurant.plan.stripeId,
      });

      if (!res.data?.ok) {
        return [];
      }

      return res.data.data;
    } catch (error) {
      logError(error);
    }

    return [];
  };

  private restaurantAdapter = ({ ednaPay, ...data }: firestore.DocumentData) =>
    ({
      ...data,
      integrations: {
        ...data.integrations,
        ...(ednaPay ? { [AvailableIntegrations.EdnaPay]: ednaPay } : {}),
      },
      createdAt: data.createdAt?.toDate() || new Date(),
      updatedAt: data.updatedAt?.toDate() || new Date(),
    } as Restaurant);

  private branchAdapter = (data: firestore.DocumentData) =>
    ({
      ...data,
      location: data.location && {
        latitude: data.location.latitude,
        longitude: data.location.longitude,
      },
      createdAt: data.createdAt?.toDate() || new Date(),
      updatedAt: data.updatedAt?.toDate() || new Date(),
    } as Branch);
}

export default new RestaurantsStore();

import { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { Schedule } from "../../models";
import { getRemainingTimer } from "../../utils/schedule";

import strings from "../../constants/strings";

export const TimedPromotion = ({
  schedule,
  tz,
  onTimeout,
}: {
  schedule: Schedule;
  tz: string;
  onTimeout?: () => void;
}) => {
  const { t } = useTranslation();
  const [duration, setDuration] = useState<moment.Moment>();

  useEffect(() => {
    const timer = getRemainingTimer(schedule, tz, setDuration, () => {
      setDuration(undefined);
      onTimeout?.();
    });

    if (!timer) {
      setDuration(undefined);
      onTimeout?.();
    }

    return () => {
      timer?.stop();
    };
  }, [onTimeout, schedule, tz]);

  return (
    <span>
      {duration
        ? `${t(strings.endsIn)} ${duration.format("HH:mm:ss")}`
        : t(strings.promotionHasEnded)}
    </span>
  );
};

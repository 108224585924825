import { storage } from "firebase/app";
import { v4 as uuid } from "uuid";

import ImageTools from "../services/images";

export type FirebaseSubscriber = {
  id: string;
  unsubscribe: () => void;
};

export const uploadImage = async (
  img: File,
  path?: string
): Promise<string | null> => {
  const imageTools = new ImageTools();
  const thumbSize = Number(process.env.REACT_APP_THUMBNAIL_SIZE) || 500;
  const blob = await imageTools.resize(img, {
    width: thumbSize,
    height: thumbSize,
  });

  if (!blob) return null;

  const ref = storage()
    .ref("images")
    .child(path || uuid());
  await ref.put(blob);

  return ref.getDownloadURL();
};

export const uploadFiles = async (file: File[]): Promise<string[]> => {
  const ref = storage().ref(`files`);

  const tasks = file.map(async (file) => {
    const fileRef = ref.child(uuid());
    await fileRef.put(file);
    return fileRef.getDownloadURL();
  });

  return Promise.all(tasks);
};

export const deleteFiles = async (urls: string[]): Promise<void> => {
  const ref = storage().ref(`files`);

  const tasks = urls.map(async (url) => {
    const idx = url.lastIndexOf("/");
    const fileRef = ref.child(url.substring(idx + 1));
    await fileRef.delete();
  });

  Promise.all(tasks);
};

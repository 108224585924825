import React from "react";
import { TFunctionResult } from "i18next";
import clsx from "clsx";
import { useField, FieldConfig } from "formik";

type Props = {
  label: React.ReactChild | TFunctionResult;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  useTextArea?: boolean;
  tooltip?: string;
  onKeyUp?: (e: any) => void;
  isOk: boolean;
  showOK?: boolean;
  failureMessage?: string;
} & FieldConfig<string>;

export const FieldCheck = (props: Props) => {
  const [field, meta] = useField(props);

  const label = typeof props.label === "function" ? props.label() : props.label;

  const inputProps = {
    ...field,
    className: clsx("input", {
      "is-danger": meta.touched && meta.error,
    }),
    type: props.type,
    placeholder: props.placeholder,
    disabled: props.disabled,
    readOnly: props.readOnly,
    tooltip: props.tooltip,
    onKeyUp: props.onKeyUp
  };

  return (
    <div className="field">
      <label className="label" htmlFor={props.name}>
        {label}
        {!!props.tooltip && <p className="label is-small">({props.tooltip})</p>}
      </label>
      <div className={clsx("control", { "has-icons-left": !!props.showOK })}>
        {props.useTextArea ? (
          <textarea {...inputProps} />
        ) : (
          <input id={props.name} {...inputProps} />
        )}
        {props.showOK && props.isOk && (
          <span className="icon is-small is-left" style={{ color: "Green" }}>
            <i className={clsx("fas", "fa-check-circle is-left")}></i>
          </span>
        )}
        {props.showOK && !props.isOk && (
          <span className="icon is-small is-left" style={{ color: "Tomato" }}>
            <i className={clsx("fas", "fa-times is-left")}></i>
          </span>
        )}
      </div>
      {meta.touched && !!meta.error && (
        <p className="help is-danger">{meta.error}</p>
      )}
      {props.showOK && !props.isOk &&
        <small className="is-size-8">{props.failureMessage}</small>
      }
    </div>
  );
};

import React, { useState } from "react";
import { useField, FieldConfig } from "formik";
import clsx from "clsx";

export const MultiAdd = (
  props: FieldConfig<string[]> & {
    label: string;
    icon?: string;
    placeholder?: string;
    disabled?: boolean;
    readOnly?: boolean;
    tooltip?: string;
    advertise? : string;
  }
) => {
  const [, { value, touched, error }, { setValue }] = useField(props);
  const [text, setText] = useState("");

  const handleAdd = () => {
    if (!text) {
      return;
    }
    if (!!value && value.includes(text)) {
      setText("");
      return;
    }
    setValue([...value, text]);
    setText("");
  };

  const handleDelete = (t: string) => () => {
    setValue(value.filter((f) => f !== t));
  };

  return (
    <div className="field">
      <label className="label">
        {props.label}
        {!!props.advertise && <p className="label is-small isred">{props.advertise}</p>}
        {!!props.tooltip && <p className="label is-small">({props.tooltip})</p>}
      </label>
      <div
        className={clsx("control has-icons-right", {
          "has-icons-left": !!props.icon,
        })}
      >
        <input
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleAdd();
            }
          }}
          onChange={(e) => setText(e.currentTarget.value)}
          value={text}
          className="input"
          type={props.type}
          placeholder={props.placeholder}
          disabled={props.disabled}
          readOnly={props.readOnly}
        />

        {!!props.icon && (
          <span className="icon is-small is-left">
            <i className={clsx("fas", props.icon)}></i>
          </span>
        )}

        <span
          className="icon is-small is-right is-clickable"
          style={{ pointerEvents: "all" }}
          onClick={handleAdd}
        >
          <i className="fas fa-plus-circle"></i>
        </span>
      </div>
      {!!value && !!value.length && (
        <div className="panel is-shadowless is-bordered mt-2">
          {value.map((m, i) => (
            <div key={m} className="panel-block is-flex is-flex-safe-width">
              <span style={{ flex: 1 }}>
                {m}
                {touched && Array.isArray(error) && error[i] && (
                  <>
                    <br />
                    <p className="help is-danger">{error[i]}</p>
                  </>
                )}
              </span>
              <button
                className="delete is-small ml-2"
                onClick={handleDelete(m)}
              />
            </div>
          ))}
        </div>
      )}
      {touched && !!error && !Array.isArray(error) && (
        <p className="help is-danger">{error}</p>
      )}
    </div>
  );
};

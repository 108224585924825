import React from "react";
import { useTranslation } from "react-i18next";
import { useField, FieldConfig } from "formik";
import { Link } from "react-router-dom";

import strings from "../../constants/strings";
import routes from "../../constants/routes";

import { PlanType } from "../../models";

import { useStore } from "../../stores";

export const Managers = (
  props: FieldConfig<{ email: string; password?: string }[]> & {
    disabled?: boolean;
    label?: string;
    planType: PlanType;
  }
) => {
  const { t } = useTranslation();
  const [, { value, touched, error }, { setValue }] = useField(props);
  const { selectedRestaurant } = useStore("restaurants");

  const handleEmailChange = (index: number) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) =>
    setValue(
      value.map((m, i) =>
        i === index ? { ...m, email: event.currentTarget.value } : m
      )
    );
  const handlePasswordChange = (index: number) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) =>
    setValue(
      value.map((m, i) =>
        i === index ? { ...m, password: event.currentTarget.value } : m
      )
    );
  const handleAdd = () => setValue([...value, { email: "", password: "" }]);
  const handleRemove = (index: number) => () =>
    setValue(value.filter((_, i) => i !== index));

  const isDisabled = props.disabled || props.planType === PlanType.Basic;

  return (
    <div className="field">
      <label className="label">
        {props.label}
        {props.planType === PlanType.Basic && (
          <Link
            to={routes.restaurantSettings.replace(
              ":restaurantId",
              selectedRestaurant?.id || ""
            )}
          >
            <small className="ml-2">
              (
              <img
                src="/images/crown.png"
                alt="crown"
                style={{ width: 24, height: 24, verticalAlign: "bottom" }}
              />{" "}
              {t(strings.proFeature)})
            </small>
          </Link>
        )}
      </label>
      <table className="table is-bordered is-fullwidth">
        <thead>
          <tr>
            <th>{t(strings.email)}</th>
            <th>{t(strings.password)}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {value?.map((c, i) => (
            <tr key={i}>
              <td>
                <input
                  className="input mb-2"
                  onChange={handleEmailChange(i)}
                  value={c.email || ""}
                  disabled={isDisabled}
                />
                {touched && Array.isArray(error) && error[i]?.email && (
                  <>
                    <br />
                    <p className="help is-danger">{error[i]?.email}</p>
                  </>
                )}
              </td>
              <td>
                <input
                  className="input"
                  type="password"
                  onChange={handlePasswordChange(i)}
                  value={c.password || ""}
                  disabled={isDisabled}
                />
                {touched && Array.isArray(error) && error[i]?.password && (
                  <>
                    <br />
                    <p className="help is-danger">{error[i]?.password}</p>
                  </>
                )}
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <button
                  className="delete"
                  type="button"
                  disabled={isDisabled}
                  onClick={handleRemove(i)}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={4}>
              <button
                type="button"
                className="button is-light is-rounded"
                onClick={handleAdd}
                disabled={isDisabled}
              >
                {t(strings.add)}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      {touched && !!error && !Array.isArray(error) && (
        <p className="help is-danger">{error}</p>
      )}
    </div>
  );
};

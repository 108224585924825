import { MenuModifierItem } from "./Menu";
import {
  PaymentMethod,
  DeliveryZone,
  DeliveryType,
} from "./Branch";
import { Coupon } from "./Restaurant";
import { Location } from "./Location";
import { AvailableIntegrations } from "./Integration";

export enum OrderStatus {
  Creating = 0,
  Received = 1,
  Preparing = 2,
  AwaitingDelivery = 3,
  Delivered = 4,
  Canceled = 5,
  CanceledByUser = 6,
}

export enum Channel {
  Messenger = "messenger",
  WhatsApp = "whatsapp",
  WebAdmin = "web_admin",
  Web = "web",
}

export type OrderItemModifier = {
  menuModifierId: string;
  minimumSelected?: number;
  maximumSelected?: number;
  isRequired: boolean;
  name: string;
  items: (MenuModifierItem & { quantity?: number })[];
};

export type OrderItem = {
  menuItemId: string;
  internalCode:string;
  name: string;
  description?: string;
  modifiers?: OrderItemModifier[];
  price: number;
  categories: string[];
  quantity: number;
  note?: string;
  image?: string;
};

export const ArrivalMinutesDifference = 15;

export type DeliveryInfo = {
  zone?: DeliveryZone & { city: string; state: string; postalCode: string };
  address?: string;
  phone?: string;
  name?: string;
  type?: DeliveryType;
  arrivalTime?: string;
  arrivalMinutes?: number;
  location?: Location;
  notes?: string;
};

export type Order = {
  id: string;
  readableId: string;
  channel: Channel;
  channelInfo: any;
  items: OrderItem[];
  subtotal: number;
  total: number;
  currency: string;
  status: OrderStatus;
  paymentMethod?: PaymentMethod;
  otherPaymentMethod?: string;
  deliveryInfo: DeliveryInfo;
  cancellationReason?: string;
  preparationTime?: number;
  coupons?: Coupon[];
  createdAt: Date;
  updatedAt: Date;
  paymentDetails?: PaymentDetails;
  integrations?: Record<AvailableIntegrations, Record<string, any>>;
};

export const channelMap = {
  [Channel.Messenger]: "messenger",
  [Channel.WebAdmin]: "webAdmin",
  [Channel.WhatsApp]: "whatsApp",
  [Channel.Web]: "web",
};

export const orderStatusMap = {
  [OrderStatus.Creating]: "creating",
  [OrderStatus.Received]: "received",
  [OrderStatus.Preparing]: "preparing",
  [OrderStatus.AwaitingDelivery]: "awaitingDelivery",
  [OrderStatus.Delivered]: "delivered",
  [OrderStatus.Canceled]: "canceled",
  [OrderStatus.CanceledByUser]: "canceledByUser",
};

export type UserUser = {
  email: boolean;
  phone: boolean;
};

export type BusinessUser = {
  kyc: boolean;
  status: boolean;
};

export type VerificationsUser = {
  user: UserUser;
  business: BusinessUser;
};

export type Gateway = {
  id: string;
  email: string;
  phone: string;
  name: string;
  verifications: VerificationsUser;
  account_type: string;
};

export type UserBusiness = {
  email: boolean;
  phone: boolean;
};

export type BusinessBusiness = {
  kyc: boolean;
  status: boolean;
};

export type VerificationsBusiness = {
  user: UserBusiness;
  business: BusinessBusiness;
};

export type Business = {
  id: string;
  email: string;
  phone: string;
  name: string;
  verifications: VerificationsBusiness;
  account_type: string;
};

export type Tx = {
  id: string;
  amount: number;
  fee: number;
  status: string;
  parentId: string;
  payment_id: string;
};

export type PaymentDetails = {
  id: string;
  gateway: Gateway;
  business: Business;
  orderId: string;
  referenceOrder: string;
  amount: number;
  currency: string;
  receipt?: any;
  tx: Tx;
  state: string;
  createdAt: Date;
  expiredAt: Date;
  callback: string;
  config: {
    fee: number;
  };
};

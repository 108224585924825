import { observable, action } from "mobx";
import { auth, firestore, User as FUser } from "firebase/app";

import { User, UserType } from "../models";
import strings from "../constants/strings";
import { logError } from "../services/logging";
import { getFirestore } from "../utils/firestore";

import settings from "./settings";
import { mergeAndRemoveDuplicates } from "../utils/arrays";

class AuthStore {
  private auth: auth.Auth;
  private db: firestore.Firestore;

  @observable initialized: boolean = false;
  @observable needSetup: boolean = false;
  @observable user: User | null = null;
  @observable isLoading: boolean = false;

  constructor() {
    this.auth = auth();
    this.db = getFirestore();

    const unsubscribe = this.auth.onAuthStateChanged(
      action(async (user: FUser | null) => {
        if (user && user.uid && !this.user) {
          const { uid } = user;
          this.user = await this.getUser(uid);

          if (!this.user) {
            this.logOut();
            return;
          }

          if (settings.language !== this.user.preferredLanguage) {
            settings.setLanguage(this.user.preferredLanguage);
          }
        }
        this.initialized = true;
        unsubscribe();
      })
    );
  }

  @action.bound
  signIn = async (email: string, password: string): Promise<string> => {
    this.isLoading = true;

    try {
      const credentials = await this.auth.signInWithEmailAndPassword(
        email,
        password
      );

      if (!credentials.user) {
        throw new Error("Something went wrong logging in");
      }

      const user = await this.getUser(credentials.user.uid);

      if (user?.isDisabled) {
        throw new Error("auth/user-disabled");
      }

      this.user = user;

      if (!this.user) {
        throw new Error("Something went wrong logging in");
      }

      if (settings.language !== this.user.preferredLanguage) {
        settings.setLanguage(this.user.preferredLanguage);
      }

      return strings.userLoggedIn;
    } catch (error) {
      switch (error.code || error.message) {
        case "auth/user-disabled":
          return strings.userDisabled;
        case "auth/invalid-email":
          return strings.invalidEmail;
        case "auth/user-not-found":
          return strings.userNotFound;
        case "auth/wrong-password":
          return strings.wrongPassword;
        default:
          logError(error);
          return strings.genericError;
      }
    } finally {
      this.isLoading = false;
    }
  };

  @action.bound
  signInWithFacebook = async (
    needsLinking: (cred: auth.AuthCredential, email: string) => void
  ): Promise<string> => {
    this.isLoading = true;

    try {
      const provider = new auth.FacebookAuthProvider();
      [
        "public_profile",
        "email",
        "pages_manage_metadata",
        "pages_messaging",
        "pages_messaging_subscriptions",
      ].forEach((s) => provider.addScope(s));

      const credentials = await this.auth.signInWithPopup(provider);

      if (!credentials.user) {
        throw new Error("Something went wrong logging in");
      }

      const user = await this.getUser(credentials.user.uid);

      if (user?.isDisabled) {
        throw new Error("auth/user-disabled");
      }

      this.user = user;

      if (!this.user) {
        await credentials.user.delete();
        return strings.needsAccountCreation;
      } else {
        this.user = await this.setUser({
          ...this.user,
          info: {
            ...this.user.info,
            facebookAccessToken: (credentials.credential?.toJSON() as any)
              .oauthAccessToken,
            id: credentials.user.providerData[0]?.uid,
            name: credentials.user.providerData[0]?.displayName,
            email: credentials.user.providerData[0]?.email || this.user.email,
            avatar: credentials.user.providerData[0]?.photoURL,
          },
          updatedAt: firestore.FieldValue.serverTimestamp() as any,
        });
      }

      if (settings.language !== this.user.preferredLanguage) {
        settings.setLanguage(this.user.preferredLanguage);
      }

      return strings.userLoggedIn;
    } catch (error) {
      // An error happened.
      if (error.code === "auth/account-exists-with-different-credential") {
        // Step 2.
        // User's email already exists.
        // The pending Facebook credential.
        var pendingCred = error.credential;
        // The provider account's email address.
        var email = error.email;
        // Get sign-in methods for this email.
        const methods = await this.auth.fetchSignInMethodsForEmail(email);
        // Step 3.
        // If the user has several sign-in methods,
        // the first method in the list will be the "recommended" method to use.
        if (methods[0] === "password") {
          // Asks the user their password.
          needsLinking(pendingCred, email);

          return strings.needsAccountLinking;
        }
      }

      switch (error.code || error.message) {
        case "auth/user-disabled":
          return strings.userDisabled;
        case "auth/invalid-email":
          return strings.invalidEmail;
        case "auth/user-not-found":
          return strings.userNotFound;
        case "auth/wrong-password":
          return strings.wrongPassword;
        default:
          logError(error);
          return strings.genericError;
      }
    } finally {
      this.isLoading = false;
    }
  };

  @action.bound
  linkFacebook = async () => {
    if (!this.auth.currentUser || !this.user) {
      return strings.genericError;
    }

    this.isLoading = true;

    try {
      const provider = new auth.FacebookAuthProvider();
      [
        "public_profile",
        "email",
        "pages_manage_metadata",
        "pages_messaging",
        "pages_messaging_subscriptions",
      ].forEach((s) => provider.addScope(s));

      const credentials = await this.auth.currentUser.linkWithPopup(provider);

      if (!credentials.user) {
        throw new Error("Something went wrong logging in");
      }

      this.user = await this.setUser({
        ...this.user,
        info: {
          ...this.user.info,
          facebookAccessToken: (credentials.credential?.toJSON() as any)
            .oauthAccessToken,
          id: credentials.user.providerData[0]?.uid,
          name: credentials.user.providerData[0]?.displayName,
          email: credentials.user.providerData[0]?.email || this.user.email,
          avatar: credentials.user.providerData[0]?.photoURL,
        },
        updatedAt: firestore.FieldValue.serverTimestamp() as any,
      });

      return strings.updateSucceeded;
    } catch (error) {
      logError(error);
      return strings.genericError;
    } finally {
      this.isLoading = false;
    }
  };

  @action.bound
  signUp = async (
    email: string,
    password: string,
    restaurantName: string,
    phoneNumber: string,
    name: string,
    referral: string
  ): Promise<string> => {
    this.isLoading = true;

    try {
      const credentials = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      );

      if (!credentials.user) {
        throw new Error("Something went wrong signing up");
      }
      //quiza
      this.user = await this.setUser({
        id: credentials.user.uid,
        email,
        type: UserType.Client,
        isDisabled: false,
        preferredLanguage: settings.language,
        restaurantName,
        phoneNumber,
        name,
        referral,
        createdAt: firestore.FieldValue.serverTimestamp() as any,
        updatedAt: firestore.FieldValue.serverTimestamp() as any,
        restaurantIds: []
      });

      // await credentials.user.sendEmailVerification();

      if (settings.language !== this.user.preferredLanguage) {
        settings.setLanguage(this.user.preferredLanguage);
      }

      return strings.userCreated;
    } catch (error) {
      switch (error.code || error.message) {
        case "auth/user-disabled":
          return strings.userDisabled;
        case "auth/invalid-email":
          return strings.invalidEmail;
        case "auth/user-not-found":
          return strings.userNotFound;
        case "auth/wrong-password":
          return strings.wrongPassword;
        case "auth/email-already-in-use":
          return strings.emailAlreadyInUse;
        default:
          logError(error);
          return strings.genericError;
      }
    } finally {
      this.isLoading = false;
    }
  };

  @action.bound
  updateCurrentUser = async ({
    password,
    ...user
  }: User & { password?: string }): Promise<string> => {
    this.isLoading = true;

    try {
      if (user.email.toLowerCase() !== this.user?.email) {
        await this.auth.currentUser?.updateEmail(user.email);
      }

      if (password) {
        await this.auth.currentUser?.updatePassword(password);
      }

      this.user = await this.setUser({
        ...user,
        updatedAt: firestore.FieldValue.serverTimestamp() as any,
      });

      return strings.updateSucceeded;
    } catch (error) {
      switch (error.code) {
        case "auth/requires-recent-login":
          return strings.requiresRecentLogin;
        case "auth/email-already-in-use":
          return strings.emailAlreadyInUse;
        case "auth/invalid-email":
          return strings.invalidEmail;
        case "auth/weak-password":
          return strings.weakPassword;
        default:
          logError(error);
          return strings.genericError;
      }
    } finally {
      this.isLoading = false;
    }
  };

  setUsersRestaurantId = async (id: string) => {
    if (!this.user) {
      throw new Error("Not logged in");
    }

    if (this.user.type !== UserType.Administrator) {
      this.user = await this.setUser({
        ...this.user,
        updatedAt: firestore.FieldValue.serverTimestamp() as any,
        restaurantId: id,
        restaurantIds: mergeAndRemoveDuplicates(
          this.user?.restaurantId,
          id,
          this.user?.restaurantIds
        ),
      });
    }
  };

  @action.bound
  logOut = async (): Promise<void> => {
    this.isLoading = true;

    try {
      await this.auth.signOut();
      this.user = null;
    } catch (error) {
      logError(error);
    } finally {
      this.isLoading = false;
      window.location.reload(true);
    }
  };

  @action.bound
  resendVerificationEmail = async (): Promise<string> => {
    const user = this.auth.currentUser;

    if (!user) {
      return strings.genericError;
    }

    this.isLoading = true;

    try {
      await user.sendEmailVerification();
    } catch (error) {
      logError(error);
      return strings.genericError;
    } finally {
      this.isLoading = false;
    }

    return strings.verificationEmailSent;
  };

  @action.bound
  verifyConfirmedEmail = async (): Promise<string> => {
    if (this.auth.currentUser === null || this.user === null) {
      return strings.genericError;
    }

    this.isLoading = true;

    try {
      await this.auth.currentUser.reload();
      if (this.auth.currentUser.emailVerified) {
        return strings.emailVerified;
      } else {
        return strings.emailNotVerified;
      }
    } catch (error) {
      logError(error);
      return strings.genericError;
    } finally {
      this.isLoading = false;
    }
  };

  @action.bound
  sendResetPassword = async (email: string): Promise<string> => {
    this.isLoading = true;

    try {
      await this.auth.sendPasswordResetEmail(email);
      return strings.recoveryEmailSent;
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
          return strings.invalidEmail;
        case "auth/user-not-found":
          return strings.userNotFound;
        default:
          logError(error);
          return strings.genericError;
      }
    } finally {
      this.isLoading = false;
    }
  };

  @action.bound
  reauthenticate = async (email: string, password: string): Promise<string> => {
    this.isLoading = true;

    try {
      await this.auth.currentUser?.reauthenticateWithCredential(
        auth.EmailAuthProvider.credential(email, password)
      );

      return strings.userLoggedIn;
    } catch (error) {
      switch (error.code) {
        case "auth/user-not-found":
          return strings.userNotFound;
        case "auth/wrong-password":
          return strings.wrongPassword;
        default:
          logError(error);
          return strings.genericError;
      }
    } finally {
      this.isLoading = false;
    }
  };

  private getUser = async (id: string): Promise<User | null> => {
    const snapshot = await this.db.collection("users").doc(id).get();

    if (snapshot.exists) {
      return snapshot.data() as User;
    }

    return null;
  };

  private setUser = async (user: User): Promise<User> => {
    user.email = user.email.toLowerCase();

    if (user.createdAt !== user.updatedAt) {
      delete (user as any).createdAt;
    }

    await this.db.collection("users").doc(user.id).set(user, { merge: true });

    return user;
  };
}

export default new AuthStore();

import React, { FC } from "react";
import { useDebouncedCallback } from "use-debounce";

import { Location } from "../../models";

type Props = {
  defaultLocation: Location;
  zoom?: number;
  onChangeLocation?(lat: number, lng: number): void;
  onChangeZoom?(zoom: number): void;
  style?: any;
  className?: string;
  options?: any;
};

function isValidLocation(location: Location) {
  return (
    location &&
    Math.abs(location.latitude) <= 90 &&
    Math.abs(location.longitude) <= 180
  );
}

const MapPicker: FC<Props> = ({
  defaultLocation,
  zoom = 7,
  onChangeLocation,
  onChangeZoom,
  style,
  className,
  options,
}) => {
  const mapContainer = React.useRef<HTMLDivElement>(null);
  const map = React.useRef<google.maps.Map>();
  const marker = React.useRef<google.maps.Marker>();
  const gpsControl = React.useRef<HTMLDivElement>();

  const centerToCurrentLocation = useDebouncedCallback(
    () =>
      navigator.geolocation?.getCurrentPosition((position) => {
        const coords = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        map.current?.panTo(coords);
        marker.current?.setPosition(coords);
        map.current?.setZoom(15);
        handleChangeLocation();
        if (gpsControl.current) gpsControl.current.hidden = true;
      }),
    1000,
    { leading: true }
  );

  const setUpControl = (controlDiv: HTMLDivElement) => {
    // Set CSS for the control border.
    const controlUI = document.createElement("div");
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginRight = "10px";
    controlUI.style.textAlign = "center";
    controlUI.style.width = "40px";
    controlUI.title = "Click to recenter the map";
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    const controlText = document.createElement("div");
    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "16px";
    controlText.style.lineHeight = "38px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = '<i class="fas fa-location-arrow"></i>';
    controlUI.appendChild(controlText);

    // Setup the click event listeners
    controlUI.addEventListener("click", centerToCurrentLocation.callback);

    return controlUI;
  };

  function handleChangeLocation() {
    if (onChangeLocation) {
      const currentLocation = marker.current?.getPosition();
      currentLocation &&
        onChangeLocation(currentLocation.lat(), currentLocation.lng());
      if (gpsControl.current) gpsControl.current.hidden = false;
    }
  }

  function handleChangeZoom() {
    onChangeZoom && map.current && onChangeZoom(map.current.getZoom());
  }

  function loadMap() {
    const Google = (window as any).google;
    const validLocation = { lat: 0, lng: 0 };

    if (isValidLocation(defaultLocation)) {
      validLocation.lat = defaultLocation.latitude;
      validLocation.lng = defaultLocation.longitude;
    }

    map.current = new Google.maps.Map(mapContainer.current, {
      center: validLocation,
      zoom: zoom,
      ...options,
    });

    if (!marker.current) {
      marker.current = new Google.maps.Marker({
        position: validLocation,
        map: map.current,
        draggable: true,
      });
      Google.maps.event.addListener(
        marker.current,
        "dragend",
        handleChangeLocation
      );
    } else {
      marker.current.setPosition(validLocation);
    }

    map.current?.addListener("click", function (event: any) {
      const clickedLocation = event.latLng;

      marker.current?.setPosition(clickedLocation);
      handleChangeLocation();
      map.current?.panTo(clickedLocation);
    });

    map.current?.addListener("zoom_changed", handleChangeZoom);

    if (navigator.geolocation) {
      const centerControlDiv = document.createElement("div");
      gpsControl.current = setUpControl(centerControlDiv);

      (centerControlDiv as any).index = 1;
      map.current?.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        centerControlDiv
      );
    }
  }

  React.useEffect(() => {
    loadMap();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (marker.current && defaultLocation) {
      map.current?.setCenter({
        lat: defaultLocation.latitude,
        lng: defaultLocation.longitude,
      });
      marker.current.setPosition({
        lat: defaultLocation.latitude,
        lng: defaultLocation.longitude,
      });
    }
  }, [defaultLocation]);

  React.useEffect(() => {
    if (map.current) {
      map.current.setZoom(zoom);
    }
  }, [zoom]);

  const componentStyle = Object.assign(
    { width: "100%", height: "600px" },
    style || {}
  );

  return (
    <div ref={mapContainer} style={componentStyle} className={className}></div>
  );
};
export default MapPicker;

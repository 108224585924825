import React, { useRef, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import routes from "../../constants/routes";
import strings from "../../constants/strings";
import { useStore } from "../../stores";
import { UserType, PlanType } from "../../models";

export const NavBar = observer(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const { user, logOut } = useStore("auth");
  const { selectedRestaurant, selectedBranch } = useStore("restaurants");
  const navBarMenuRef = useRef<HTMLDivElement>(null);
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!navBarMenuRef.current) {
        return;
      }

      navBarMenuRef.current.classList.toggle("is-active");
      e.currentTarget.classList.toggle("is-active");
    },
    []
  );
  const links = [];

  const showButton = () => {
    if (!!user) {
      if (location.pathname.toLowerCase() !== routes.restaurants) {
        if (
          user?.type === UserType.Administrator ||
          user?.type === UserType.Client
        ) {
          return true;
        }
      }
    }
    return false;
  };

  if (selectedRestaurant && selectedBranch) {
    if (user && [UserType.Administrator, UserType.Client].includes(user.type)) {
      links.push({
        route: routes.restaurantBranches.replace(
          ":restaurantId",
          selectedRestaurant.id
        ),
        label: strings.branches,
        disable: ""
      });
    }
    links.push(
      {
        route: routes.branchOrders
          .replace(":restaurantId", selectedRestaurant.id)
          .replace(":branchId", selectedBranch.id),
        label: strings.orders,
        disable: ""
      },
      {
        route: routes.branchMenu
          .replace(":restaurantId", selectedRestaurant.id)
          .replace(":branchId", selectedBranch.id),
        label: strings.branchMenu,
        disable: ""
      }
    );
    if (user && [UserType.Administrator, UserType.Client].includes(user.type)) {
      links.push({
        route: routes.branchProfile
          .replace(":restaurantId", selectedRestaurant.id)
          .replace(":branchId", selectedBranch.id),
        label: strings.branchProfile,
        disable: ""
      });
    }
  } else if (selectedRestaurant) {
    links.push(
      {
        route: routes.restaurantBranches.replace(
          ":restaurantId",
          selectedRestaurant.id
        ),
        label: strings.branches,
        disable: ""
      },
      {
        route: routes.restaurantMenu.replace(
          ":restaurantId",
          selectedRestaurant.id
        ),
        label: strings.menu,
        disable: ""
      },
      {
        route: routes.restaurantSettings.replace(
          ":restaurantId",
          selectedRestaurant.id
        ),
        label: strings.settings,
        disable: ""
      },
      {
        route: routes.restaurantIntegrations.replace(
          ":restaurantId",
          selectedRestaurant.id
        ),
        label: strings.integrations,
        disable: (selectedRestaurant?.plan.type !== PlanType.Pro ? " disabled-link" : "")
      },
      {
        route: routes.restaurantProfile.replace(
          ":restaurantId",
          selectedRestaurant.id
        ),
        label: strings.restaurantProfile,
        disable: ""
      }
    );
  } else if (user?.type === UserType.Administrator) {
    links.push({ route: routes.restaurants, label: strings.restaurants, disable: "" });
  }

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to={routes.home}>
          <img src="/images/logo.png" width="112" height="28" alt="logo" />
        </Link>

        <button
          className="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          onClick={handleClick}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div ref={navBarMenuRef} className="navbar-menu">
        <div className="navbar-start">
          {links.map((link, i) => (
            <div key={i} className="flexRow">
              <Link
                key={link.route}
                className={clsx("navbar-item", {
                  "is-active": location.pathname === link.route,
                })}
                to={link.route}
              >
                {t(link.label)}
              </Link>
              {selectedRestaurant?.plan.type !== PlanType.Pro && link.disable !== "" &&
                <Link
                  to={routes.restaurantSettings.replace(
                    ":restaurantId",
                    selectedRestaurant?.id || ""
                  )}
                >
                  <div className="pt-3 mr-2">
                    <img
                      src="/images/crown.png"
                      alt="crown"
                      style={{ width: 24, height: 24, verticalAlign: "bottom" }}
                    />
                  </div>
                </Link>
              }
            </div>
          ))}
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <a
                className="button is-primary"
                target="_blank"
                href="/misc/Guía_de_Usuario_EDNA_FOODS.pdf"
              >
                {t(strings.howToDocument)}
              </a>
              {showButton() && (
                <Link className="button is-primary" to={routes.restaurants}>
                  {t(strings.restaurants)}
                </Link>
              )}
              {!user ? (
                <>
                  <Link className="button is-primary" to={routes.signUp}>
                    <strong>{t(strings.signUp)}</strong>
                  </Link>
                  <Link className="button is-light" to={routes.signIn}>
                    {t(strings.signIn)}
                  </Link>
                </>
              ) : (
                <button className="button is-light" onClick={logOut}>
                  {t(strings.signOut)}
                </button>
              )}
            </div>
          </div>
          {user && (
            <>
              <div className="is-flex is-flex pl-4 pr-6 is-list-item">
                <i
                  className="fas fa-user mt-3"
                  aria-hidden="true"
                  style={{ fontSize: "2.2rem" }}
                ></i>
                <div className="is-flex is-flex-grow-1 is-align-items-stretch is-justify-content-center is-flex-direction-column ml-3">
                  <div className="is-flex is-justify-content-space-between">
                    <strong className="mr-2">
                      <small>{user.name}</small>
                      {user?.type === UserType.Administrator && (
                        <small>({t(strings.admin)})</small>
                      )}
                    </strong>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
});

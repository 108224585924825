import moment from "moment-timezone";

import { Schedule, Day } from "../models";
import Timer from "./timer";

export const defaultSchedule = {
  sunday: {
    from: "00:00",
    to: "00:00",
  },
  monday: {
    from: "00:00",
    to: "00:00",
  },
  tuesday: {
    from: "00:00",
    to: "00:00",
  },
  wednesday: {
    from: "00:00",
    to: "00:00",
  },
  thursday: {
    from: "00:00",
    to: "00:00",
  },
  friday: {
    from: "00:00",
    to: "00:00",
  },
  saturday: {
    from: "00:00",
    to: "00:00",
  },
};

const isoMapping: { [key: number]: Day } = {
  1: Day.Monday,
  2: Day.Tuesday,
  3: Day.Wednesday,
  4: Day.Thursday,
  5: Day.Friday,
  6: Day.Saturday,
  7: Day.Sunday,
};

export const verifyIsOpen = (schedule: Schedule, tz: string) => {
  const now = moment.tz(tz);
  const day = isoMapping[now.isoWeekday()];
  const todaySchedule = schedule[day];

  if (!todaySchedule || !todaySchedule.from || !todaySchedule.to) {
    return true;
  }

  const from = moment(todaySchedule.from, "HH:mm").tz(tz);

  let to = moment(todaySchedule.to, "HH:mm").tz(tz);

  if (from.isAfter(to)) {
    to = to.add(1, "day");
  }

  return now.isBetween(from, to);
};

export const getRemainingTimer = (
  schedule: Schedule,
  tz: string,
  tick: (duration: moment.Moment) => void,
  timeOut: () => void
) => {
  const now = moment.tz(tz);
  const day = isoMapping[now.isoWeekday()];
  const todaySchedule = schedule[day];

  if (!todaySchedule || !todaySchedule.from || !todaySchedule.to) {
    return null;
  }

  const from = moment(todaySchedule.from, "HH:mm").tz(tz);

  let to = moment(todaySchedule.to, "HH:mm").tz(tz);

  if (from.isAfter(to)) {
    to = to.add(1, "day");
  }

  tick(moment.utc(to.diff(now)));

  const limit = to.unix();

  const timer = new Timer(1000, ({ stop }) => {
    const n = moment.tz(tz);
    if (n.unix() >= limit) {
      stop();
      timeOut();
    } else {
      tick(moment.utc(to.diff(n)));
    }
  });

  timer.start();

  return timer;
};

import React from "react";
import { useField, FieldConfig } from "formik";
import { CardElement, useElements } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";

type Props = {
  disabled?: boolean;
} & FieldConfig<StripeCardElement | null | undefined>;

export const CardField = (props: Props) => {
  const elements = useElements();
  const [, meta, helpers] = useField(props);

  return (
    <div className="field">
      <CardElement
        options={{
          classes: {
            base: `stripe-input ${meta.error ? "is-danger" : ""}`.trim(),
            invalid: "is-danger",
            complete: "is-success",
          },
          hidePostalCode: true,
        }}
        onBlur={() => helpers.setTouched(true)}
        onChange={(e) =>
          helpers.setValue(
            e.complete ? elements?.getElement(CardElement) : undefined
          )
        }
      />
      {meta.touched && !!meta.error && (
        <p className="help is-danger">{meta.error}</p>
      )}
    </div>
  );
};

import { ComponentLoader } from "../components";

import routes from "../constants/routes";

export const getPage = (route: string) => {
  let loader: () => Promise<any>;

  switch (route) {
    case routes.notFound:
      loader = () => import("./notFound/NotFound");
      break;
    case routes.signUp:
      loader = () => import("./signUp/SignUp");
      break;
    case routes.signIn:
      loader = () => import("./signIn/SignIn");
      break;
    case routes.restaurants:
      loader = () => import("./restaurants/Restaurants");
      break;
    case routes.createRestaurant:
      loader = () => import("./createRestaurant/CreateRestaurant");
      break;
    case routes.restaurantBranches:
      loader = () => import("./branches/Branches");
      break;
    case routes.branchOrders:
      loader = () => import("./orders/Orders");
      break;
    case routes.branchMenu:
      loader = () => import("./branchMenu/BranchMenu");
      break;
    case routes.restaurantSettings:
      loader = () => import("./settings/Settings");
      break;
    case routes.restaurantProfile:
      loader = () => import("./restaurantProfile/RestaurantProfile");
      break;
    case routes.createRestaurantBranch:
      loader = () => import("./createBranch/CreateBranch");
      break;
    case routes.branchProfile:
      loader = () => import("./branchProfile/BranchProfile");
      break;
    case routes.restaurantIntegrations:
      loader = () => import("./restaurantIntegrations/RestaurantIntegrations");
      break;
    case routes.restaurantMenu:
      loader = () => import("./restaurantMenu/RestaurantMenu");
      break;
    case routes.webViewMenuItemModifier:
      loader = () => import("./webView/WebViewMenuItemModifier");
      break;
    case routes.webViewConfirm:
      loader = () => import("./webView/WebViewConfirm");
      break;
    case routes.webViewDelivery:
      loader = () => import("./webView/WebViewDeliveryInfo");
      break;
    case routes.webViewBranch:
      loader = () => import("./webView/WebViewBranch");
      break;
    case routes.webViewCheckOutEdnaPay:
      loader = () => import("./webView/WebViewCheckoutEdnaPay");
      break;
    case routes.webViewOrder:
      loader = () => import("./webView/order/WebViewIntro");
      break;
    case routes.webViewOrderMenu:
      loader = () => import("./webView/order/WebViewMenu");
      break;
    case routes.webViewOrderCheckout:
      loader = () => import("./webView/order/WebViewCheckout");
      break;
    case routes.webViewOrderComplete:
      loader = () => import("./webView/order/WebViewOutro");
      break;
    case routes.webViewOrderTracking:
      loader = () => import("./webView/order/WebViewOrderTracking");
      break;
    default:
      return undefined;
  }

  return ComponentLoader(loader);
};

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function sum(a: number, b: number) {
  return a + b;
}

export const compare = <T = any>(extract: (a: T) => any = (a) => a) => (
  a: any,
  b: any
) => {
  if (extract(a) < extract(b)) {
    return -1;
  }
  if (extract(a) > extract(b)) {
    return 1;
  }
  return 0;
};

export const mergeAndRemoveDuplicates = (
  singleItem1: string | undefined,
  singleItem2: string | undefined,
  itemArray: string[] | undefined
) => {
  var toReturn: string[] = [];

  if (singleItem1) {
    toReturn.push(singleItem1);
  }

  if (singleItem2) {
    toReturn.push(singleItem2);
  }

  if (itemArray) {
    toReturn = toReturn.concat(itemArray);
  }

  toReturn = toReturn.filter(function (item, pos) {
    return toReturn.indexOf(item) === pos;
  });

  return toReturn;
};

export function removeDuplicatesFromObject<T>(
  listOfIds: any[],
  objectArray: T[]
) {
  //List of unique object items to return
  var unique: T[] = [];
  //based on ids, validates only filter once from object array
  listOfIds.forEach((id) => {
    let objectFiltered = objectArray.filter((cat: any) => cat.id === id)[0];
    if (objectFiltered !== undefined) {
      if (!unique.includes(objectFiltered)) {
        unique.push(objectFiltered);
      }
    }
  });

  return unique;
}

import React from "react";

export enum IntegrationCategory {
  Delivery = "deliveryIntegration",
  Payment = "paymentIntegration",
  Hub = "hubIntegration",
  Loyalty = "loyaltyIntegration",
  ChatBot = "chatBotIntegration",
  Analytics = "analytics",
}

export interface Integration<T = Record<string, any>> {
  integrationName: string;
  image: string;
  integrationCategory: IntegrationCategory;
  countries: string[];
  integrationDependencies: AvailableIntegrations[];
  Modal?: React.FunctionComponent<any>;
  modalProps?: Record<string, any>;
  disableModalAfterSetUp?: boolean;
  settings?: T;
}

export type IntegrationSettingsField = {
  name: string;
  label: string;
  type: "number" | "text" | "password" | "email";
};

export enum AvailableIntegrations {
  EdnaPay = "ednaPay",
  Zubut = "zubut",
  Hubster = "hubster",
  EnjoyLoyalty = "enjoyLoyalty",
  ChatHub = "chatHub",
  FacebookPixel = "facebookPixel",
}

export type EDNAPayVerification = {
  email: boolean;
  phone: boolean;
  kyc: boolean;
  status: boolean;
};

export type EDNAPayUserVerifications = {
  user: EDNAPayVerification;
  business: EDNAPayVerification;
};

export type EDNAPayLinkResponse = {
  id: string;
  email: string;
  phone: string;
  name: string;
  verifications: EDNAPayUserVerifications;
  account_type: string;
};

export type EnjoyLoyaltyReponse = {
  status: number;
  points?: number;
  message?: string;
  code?: number;
};
